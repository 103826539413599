import React, { Component } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import { withStyles, Box, CircularProgress, IconButton, Typography } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { tenderTaskStatus } from '../../../Helper/CommonThings';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

const useStyles = (theme) => ({
});

class TimesheetTemplateRow1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            row: props.row,
            toDia3: props.toDia3,
            toDia2: props.toDia2,
        }
    }

    componentDidMount() {

        var toDia2 = this.state.toDia2
        toDia2[0].splice(0, 1, { title: 'DS', field: 'tproj_name' })
        this.setState({ toDia2: toDia2 })
        var row = this.state.row
        var detailPanel = []
        for (let i = 0; i < toDia2[1].length; i++) {
            const element = toDia2[1][i];
            if (row.hasOwnProperty('ttender_id')) {
                if (element.ttender_id === row.ttender_id) {
                    detailPanel.push(element)
                }
            } else if (row.hasOwnProperty('tid')) {
                if (element.tid === row.tid) {
                    detailPanel.push(element)
                }
            }
        }

        row.detailPanel = detailPanel

        this.setState({ row: row }, () => {
            var all_checked = 0
            var row = this.state.row
            for (let i = 0; i < row.detailPanel.length; i++) {
                const element = row.detailPanel[i];
                if (element.is_checked) {
                    all_checked += 1
                }
            }
            if (all_checked === row.detailPanel.length) {
                row.is_checked = true
            }
            this.setState({ row: row, isLoading: false })
        })
    }

    render() {
        return (
            this.state.isLoading ? <CircularProgress /> :
                <React.Fragment>
                    <StyledTableRow >
                        <StyledTableCell padding='default' style={{ width: 10 }}>
                            <IconButton aria-label="expand row" size="small" onClick={() => this.props.handleOpen(this.props.index)}>
                                {this.state.toDia3[1][this.props.index].is_open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            <Typography >{this.state.row.projects}</Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Typography >{this.state.row[this.state.toDia3[0][this.props.mini_table_data_start].field]}</Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Typography >{this.state.row[this.state.toDia3[0][this.props.mini_table_data_start + 1].field]}</Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Typography >{this.state.row[this.state.toDia3[0][this.props.mini_table_data_start + 2].field]}</Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Typography >{this.state.row[this.state.toDia3[0][this.props.mini_table_data_start + 3].field]}</Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Typography >{this.state.row[this.state.toDia3[0][this.props.mini_table_data_start + 4].field]}</Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Typography >{this.state.row[this.state.toDia3[0][this.props.mini_table_data_start + 5].field]}</Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Typography >{this.state.row[this.state.toDia3[0][this.props.mini_table_data_start + 6].field]}</Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Typography >{this.state.row['total']}</Typography>
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                            <Collapse in={this.state.toDia3[1][this.props.index].is_open} timeout="auto" unmountOnExit>
                                <Box margin={1}>
                                    <Typography variant="h6" gutterBottom component="div">
                                        Detailed View
                                    </Typography>
                                    <Table size="small" aria-label="detailview">
                                        <TableHead>
                                            <TableRow>
                                                {this.props.toDia2[0].map((row, i) => (
                                                    <TableCell key={i} align="center">{row.title}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.row.detailPanel.map((row, i) =>
                                                <TableRow key={i}>
                                                    <TableCell align="center">{row.tproj_name}</TableCell>
                                                    <TableCell align="center">{row.tname ? row.tname : row.ttdecs ? row.ttdecs : row.ttname}</TableCell>
                                                    <TableCell align="center">{(!row.task_hold && row.task_hold !== 0) ? "-" : row.task_hold ? <h4 style={{ color: 'red' }}>On Hold</h4> : tenderTaskStatus(row.ttstatus)}</TableCell>
                                                    <TableCell align="center">{row.comments}</TableCell>
                                                    <TableCell align="center">{row.actual_hours}</TableCell>
                                                    <TableCell align="center">{row[this.state.toDia2[0][this.props.table_data_start].field]}</TableCell>
                                                    <TableCell align="center">{row[this.state.toDia2[0][this.props.table_data_start + 1].field]}</TableCell>
                                                    <TableCell align="center">{row[this.state.toDia2[0][this.props.table_data_start + 2].field]}</TableCell>
                                                    <TableCell align="center">{row[this.state.toDia2[0][this.props.table_data_start + 3].field]}</TableCell>
                                                    <TableCell align="center">{row[this.state.toDia2[0][this.props.table_data_start + 4].field]}</TableCell>
                                                    <TableCell align="center">{row[this.state.toDia2[0][this.props.table_data_start + 5].field]}</TableCell>
                                                    <TableCell align="center">{row[this.state.toDia2[0][this.props.table_data_start + 6].field]}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </StyledTableRow>
                </React.Fragment>
        );
    }
}

export default withStyles(useStyles)(withSnackbar(TimesheetTemplateRow1));
