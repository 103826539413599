import React, { Component } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import { withStyles, Box, CircularProgress, IconButton, Typography, Checkbox } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

const useStyles = (theme) => ({
});

class TimesheetTemplateRow2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            row: props.row,
            dateColumns: props.dateColumns,
            columns: props.columns,
            detailPanelColumns: props.detailPanelColumns,
            data: props.data
        }
    }

    componentDidMount() {
        // var data = this.state.data
        // toDia2[0].splice(0, 1, { title: 'DS', field: 'tproj_name' })
        // this.setState({ toDia2: toDia2 })
        // var row = this.state.row
        // var detailPanel = []
        // for (let i = 0; i < toDia2[1].length; i++) {
        //     const element = toDia2[1][i];
        //     if (row.hasOwnProperty('ttender_id')) {
        //         if (element.ttender_id === row.ttender_id) {
        //             detailPanel.push(element)
        //         }
        //     } else if (row.hasOwnProperty('tid')) {
        //         if (element.tid === row.tid) {
        //             detailPanel.push(element)
        //         }
        //     }
        // }

        // row.detailPanel = detailPanel

        // this.setState({ row: row }, () => {
        //     var all_checked = 0
        //     var row = this.state.row
        //     for (let i = 0; i < row.detailPanel.length; i++) {
        //         const element = row.detailPanel[i];
        //         if (element.is_checked) {
        //             all_checked += 1
        //         }
        //     }
        //     if (all_checked === row.detailPanel.length) {
        //         row.is_checked = true
        // }
        this.setState({ isLoading: false })
        // })
    }


    render() {
        return (
            this.state.isLoading ? <CircularProgress /> :
                <React.Fragment>
                    <StyledTableRow >
                        <StyledTableCell padding='default' style={{ width: 10 }}>
                            <IconButton aria-label="expand row" size="small" onClick={() => this.props.handleOpen(this.props.index)}>
                                {this.state.row?.is_open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </StyledTableCell>
                        {[0, 3, 4, 5].includes(this.props.approve_status) ?
                            <StyledTableCell padding='default' style={{ width: 10 }}>
                                <Checkbox
                                    size='small'
                                    checked={this.state.row.is_checked}
                                    onChange={(e) => this.props.handleSelect(e, this.props.index)}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={this.state.row.is_all_approved}
                                />
                            </StyledTableCell> : null}
                        {
                            this.state.columns.map((column, i) => (
                                <StyledTableCell key={i} align={column.title === 'Projects' ? 'left' : 'center'}>
                                    {column.render ? column.render(this.state.row) : this.state.row[column.field]}
                                </StyledTableCell>
                            ))
                        }
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                            <Collapse in={this.state.row?.is_open} timeout="auto" unmountOnExit>
                                <Box margin={1}>
                                    <Typography variant="h6" gutterBottom component="div">
                                        Detailed View
                                    </Typography>
                                    <Table size="small" aria-label="detailview">
                                        <TableHead>
                                            <TableRow>
                                                {[0, 3, 4, 5].includes(this.props.approve_status) ? <TableCell align="center"></TableCell> : null}
                                                {this.props.detailPanelColumns.map((row, i) => (
                                                    <TableCell key={i} align="center">{row.title}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.row.detailPanel.map((row, i) =>
                                                <TableRow key={i}>
                                                    {([0, 3, 4, 5].includes(this.props.approve_status)) ?
                                                        <TableCell align="center" style={{ width: 10 }}>
                                                            <Checkbox
                                                                size='small'
                                                                checked={row.is_checked}
                                                                onChange={(e) => this.props.handleIndivSelect(e, this.props.index, i)}
                                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                disabled={[1, 2, 7].includes(row.timesheet_approval_status)}
                                                            />
                                                        </TableCell> : null}
                                                    {
                                                        this.state.detailPanelColumns.map((column, i) => (
                                                            <TableCell key={i} align={'center'}>
                                                                {column.render ? column.render(row) : row[column.field]}
                                                            </TableCell>
                                                        ))
                                                    }
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </StyledTableRow>
                </React.Fragment>
        );
    }
}

export default withStyles(useStyles)(withSnackbar(TimesheetTemplateRow2));
