import React, { Component } from 'react';
import { getReportComponents } from '../../Helper/ReportComponents';
import { getPrimaryColor } from '../../Helper/CommonThings';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { LoopCircleLoading } from 'react-loadingg';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import { screenSizeCheck } from '../../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import { getLightGreyColor } from '../../MainDashBoard/ColorTheme/ColorTheme';
import Typography from '@mui/material/Typography';
import ReactGA from 'react-ga';
import { getCookie } from '../../Helper/CookieHelper';

class ReportsDashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            reports: [],
            isLoading: true
        }
    }

    componentDidMount() {
        this.setState({ reports: getReportComponents() }, () => {
            setTimeout(() => {
                this.setState({ isLoading: false })
            }, 200);
        })
    }

    changeView = (value) => {
        if (this.props.changeView) {
            this.props.changeView(value)
            var pageView = window.location.pathname + "/#" + value;
            ReactGA.set({ page: pageView, });
            ReactGA.pageview(pageView);

            if (!getCookie("bb_ts_token")) {
                window.location.href = '/'
            } else {
                window.location.hash = value;
            }
        }
    }

    render() {
        return (
            <div>
                {this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                    <Box flexDirection="row" display="flex" >
                        <Grid container spacing={3}   >
                            {(this.state.reports).map((value, i) =>
                                <Grid item xs={screenSizeCheck() ? 4 : 3} >
                                    <Card style={{ backgroundColor: value.access ? "" : getLightGreyColor(), borderRadius: '10px' }}>
                                        {value.access ? <CardActionArea onClick={() => this.changeView(value.component)}  >
                                            <CardContent>
                                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                    <Box>
                                                        {value.icon}
                                                    </Box>
                                                    <Box marginLeft={'10px'}>
                                                        <Typography variant="h5" component="h2">
                                                            {value.name}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    {value.definition}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea> :
                                            <CardContent>
                                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                                    <Box>
                                                        {value.icon}
                                                    </Box>
                                                    <Box marginLeft={'10px'}>
                                                        <Typography variant="h5" component="h2">
                                                            {value.name}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    {value.definition}
                                                </Typography>
                                            </CardContent>}
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                    </Box>}
            </div>
        );
    }
}

export default ReportsDashboard;