import { getDarkMode } from "../../Helper/CommonThings"

var darkMode = getDarkMode()

export function getGreyColor() {
    if (darkMode === 'dark') {
        return '#d9d9d9'
    } else {
        return '#999999'
    }
}

export function getblueColor() {
    if (darkMode === 'dark') {
        return '#5c7c90'
    } else {
        return '#80AECC'
    }
}

export function getProgBgColor() {
    if (darkMode === 'dark') {
        return '#bebebe'
    } else {
        return '#e6e6e6'
    }
}

export function getTextcolor() {
    if (darkMode === 'dark') {
        return '#fff'
    } else {
        return '#000'
    }
}

export function getLaterDuecolor() {
    if (darkMode === 'dark') {
        return '#da8bc5'
    } else {
        return '#a63688'
    }
}

export function getGreencolor() {
    if (darkMode === 'dark') {
        return '#00b371'
    } else {
        return '#008755'
    }
}

export function getSkyBluecolor() {
    if (darkMode === 'dark') {
        return '#0099ff'
    } else {
        return '#005c99'
    }
}

export function getOrangecolor() {
    if (darkMode === 'dark') {
        return '#e7a356'
    } else {
        return '#e29236'
    }
}

export function getBrowncolor() {
    if (darkMode === 'dark') {
        return '#bfb361'
    } else {
        return '#91853b'
    }
}

export function getredcolor() {
    if (darkMode === 'dark') {
        return '#ff6633'
    } else {
        return '#cc3300'
    }
}

export function getMode() {
    if (darkMode === 'dark') {
        return true
    } else {
        return false
    }
}

export function getBlackAndWhite() {
    if (darkMode === 'dark') {
        return '#ffffff'
    } else {
        return '#000000'
    }
}

export function getPinkColor() {
    // if (darkMode === 'dark') {
    //     return '#ffffff'
    // } else {
    return '#cc0066'
    // }
}

export function getDarkGreenColor() {
    // if (darkMode === 'dark') {
    //     return '#ffffff'
    // } else {
    return '#339933'
    // }
}

export function getDarkGreyColor() {
    if (darkMode === 'dark') {
        return '#999999'
    } else {
        return '#b3b3b3'
    }
}

export function getLightGreyColor() {
    if (darkMode === 'dark') {
        return '#808080'
    } else {
        return '#bfbfbf'
    }
}

export function getSPICPIColor(spi, cpi) {
    if (spi === 1 && cpi === 1) {
        return {
            color: '#009900',//green
            status: 'On Budget - On Schedule'
        }
    } else if (spi < 1 && cpi < 1) {
        return {
            color: '#cc2900',//red
            status: 'Over Budget - behind the Schedule'
        }
    } else if (spi > 1 && cpi > 1) {
        return {
            color: '#009900',//green
            status: 'Under Budget - Ahead of Schedule'
        }
    } else if (spi < 1 && cpi > 1) {
        return {
            color: '#e68a00',//amber
            status: 'Under Budget - behind the Schedule'
        }
    } else if (spi > 1 && cpi < 1) {
        return {
            color: '#cc2900',//red
            status: 'Over Budget - Ahead of Schedule'
        }
    } else if (spi === 1 && cpi > 1) {
        return {
            color: '#009900',//green
            status: 'Under Budget - On Schedule'
        }
    } else if (spi === 1 && cpi < 1) {
        return {
            color: '#e68a00',//amber
            status: 'Over Budget - On Schedule'
        }
    } else if (spi > 1 && cpi === 1) {
        return {
            color: '#009900',//green
            status: 'On Budget - Ahead of Schedule'
        }
    } else if (spi < 1 && cpi === 1) {
        return {
            color: '#e68a00',//amber
            status: 'On Budget - behind the Schedule'
        }
    }
}

