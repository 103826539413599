import DataUsageIcon from '@material-ui/icons/DataUsage';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DetailsIcon from '@mui/icons-material/Details';
import DownloadingIcon from '@mui/icons-material/Downloading';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { getCookie } from './CookieHelper';
import { screenSizeCheck } from '../MainDashBoard/ScreensizeCheck/ScreensizeCheck';

const access = JSON.parse(getCookie("bb_ts_access"));

export function getReportComponents() {
    return [
        {
            name: "User Summary",
            definition: "User Summary",
            component: "User Summary",
            icon: <DataUsageIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />,
            access: (access && access.access && access.access.l15a && access.access.l15a.l15a_d)
        },
        {
            name: "Timesheet Report",
            definition: "Timesheet Report",
            component: "Timesheet Report",
            icon: <ArtTrackIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />,
            access: (access && access.access && access.access.l15a && access.access.l15a.l15a_b)
        },
        {
            name: "DemandVsCapacity",
            definition: "DemandVsCapacity",
            component: "DemandVsCapacity",
            icon: <AllInboxIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />,
            access: (access && access.access && access.access.l15a && access.access.l15a.l15a_c)
        },
        {
            name: "D vs C",
            definition: "D vs C",
            component: "D vs C",
            icon: <VerticalSplitIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />,
            access: (access && access.access && access.access.l15a && access.access.l15a.l15a_e)
        },
        {
            name: "Lookahead Report",
            definition: "Lookahead Report",
            component: "Lookahead Report",
            icon: <ListAltIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />,
            access: (access && access.access && access.access.l15a && access.access.l15a.l15a_f)
        },
        {
            name: "KPI Report",
            definition: "KPI Report",
            component: "KPI Report",
            icon: <DetailsIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />,
            access: (access && access.access && access.access.l15a && access.access.l15a.l15a_g)
        },
        {
            name: "Timesheet Download",
            definition: "Timesheet Download",
            component: "Timesheet Download",
            icon: <DownloadingIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />,
            access: (access && access.access && access.access.l15a && access.access.l15a.l15a_h)
        },
        {
            name: "CM Risk",
            definition: "CM Risk",
            component: "CM Risk",
            icon: <PublishedWithChangesIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />,
            access: (access && access.access && access.access.l22a && access.access.l22a.l22a_a)
        },
        {
            name: "HOE Gate6",
            definition: "HOE Gate6",
            component: "HOE Gate6",
            icon: <AssessmentIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />,
            access: (access && access.access && access.access.l15a && access.access.l15a.l15a_i)
        }, {
            name: "Members Workload",
            definition: "Members Workload",
            component: "Members Workload",
            icon: <AssignmentIndIcon fontSize={screenSizeCheck() ? 'medium' : 'large'} />,
            access: (access && access.access && access.access.l15a && access.access.l15a.l15a_k)
        },
    ]
}