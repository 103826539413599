import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import FilterListIcon from '@material-ui/icons/FilterList';
import { Box, TextField } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// import { json2excel } from 'js2excel';
import XLSX from 'xlsx';

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// var rows = [
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Donut', 452, 25.0, 51, 4.9),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
//   createData('Honeycomb', 408, 3.2, 87, 6.5),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Jelly Bean', 375, 0.0, 94, 0.0),
//   createData('KitKat', 518, 26.0, 65, 7.0),
//   createData('Lollipop', 392, 0.2, 98, 0.0),
//   createData('Marshmallow', 318, 0, 81, 2.0),
//   createData('Nougat', 360, 19.0, 9, 37.0),
//   createData('Oreo', 437, 18.0, 63, 4.0),
// ];

// var rows = []

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function descendingComparator2(a, b, orderBy) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  if (orderBy === "name") {
    return order === 'desc'
      ? (a, b) => descendingComparator2(a.uid_data.first_name, b.uid_data.first_name, orderBy)
      : (a, b) => -descendingComparator2(a.uid_data.first_name, b.uid_data.first_name, orderBy);
  } else if (orderBy === "loc") {
    return order === 'desc'
      ? (a, b) => descendingComparator2(a.uid_data.office_loc, b.uid_data.office_loc, orderBy)
      : (a, b) => -descendingComparator2(a.uid_data.office_loc, b.uid_data.office_loc, orderBy);
  } else {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// var headCells = [];
// var topCol = [];
// var tableName =""

// var headCells = [
//   { id: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
//   { id: 'calories', numeric: true, disablePadding: false, label: 'Calories' },
//   { id: 'fat', numeric: true, disablePadding: false, label: 'Fat (g)' },
//   { id: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
//   { id: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
// ];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.topCol.map((col12, index) => (
          index === 0 ?
            <TableCell align="center" colSpan={3}>

            </TableCell>
            :
            <TableCell align="center" colSpan={3}>
              {col12}
            </TableCell>
        ))}
      </TableRow>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell> */}
        {props.headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {props.name}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Box display={'flex'} flexDirection={'row'}>
          <TextField style={{ marginRight: "10px" }} id="timesheetsummaryuser" label="Search User" variant="outlined" size="meduim" onChange={(e) => { props.handleFilter(e.target.value) }} />
          <Tooltip title="Download">
            <IconButton onClick={() => props.downloadExcel()}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function CustomTable1(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('decs');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [rows, setRows] = React.useState(JSON.parse(JSON.stringify(props.rows)));
  const [headCells] = React.useState(props.headCells);
  const [topCol] = React.useState(props.topCol);
  const [tableName] = React.useState(props.tableName);
  // rows = props.rows
  // headCells = props.headCells
  // topCol = props.topCol
  // tableName = props.tableName
  console.log(props)

  const handleRequestSort = (event, property) => {

    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilter = (text) => {
    // console.log(text)
    var dataInto = []
    for (let i = 0; i < props.rows.length; i++) {
      const element = props.rows[i];
      if ((element.uid_data.last_name + " " + element.uid_data.first_name).toLowerCase().includes(text.toLowerCase())) {
        dataInto.push(element)
      }
    }
    setRows(dataInto)
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const downloadExcel = () => {
    // let down = [];
    let data = JSON.parse(JSON.stringify(rows))
    props.topCol.shift()
   
    // console.log(data, name)
    // for (let i = 0; i < data.length; i++) {
    //   let down2 = {};
    //   const element = data[i];
    //   if (element) {
    //     down2["Name"] = element.uid_data.last_name + ", " + element.uid_data.first_name
    //     down2["EDC/UK"] = element.uid_data.office_loc === 0 ? "UK" : "EDC"
    //     down2["Teams"] = element.teams
    //     down2["Contracted"] = element[headCells[3].id]
    //     down2["Completed"] = element[headCells[4].id]
    //     down2["Difference"] = element[headCells[5].id]
    //     down.push(down2)
    //   }
    // }

    const workbook = XLSX.utils.book_new();
    for (let index = 0; index < props.topCol.length; index++) {
      const element = props.topCol[index];
      const ele = element.split(" ")
      
    let down = data.map(row =>({
      "Name": row.uid_data.last_name + ", " + row.uid_data.first_name,
      "EDC/UK/SL": row.uid_data.office_loc === 0 ? "UK" : row.uid_data.office_loc === 1 ? "EDC" : row.uid_data.office_loc === 2 ? "SL" : "NA",
      "Teams": row.teams,
      "Contracted": row[ele[1]+'cont'],
      "Completed": row[ele[1]+'comp'],
      "Difference": row[ele[1]+'bal']
    }))

    const worksheet = XLSX.utils.json_to_sheet(down);
    
    XLSX.utils.book_append_sheet(workbook, worksheet, element);
    }

    XLSX.writeFile(workbook, "Users Timesheet Submission Summary Status.xlsx", { compression: true });

    // try {
    //   json2excel({
    //     data: down,
    //     name: name,
    //     formateDate: 'dd/mm/yyyy'
    //   });
    // } catch (e) {
    //   console.error(e);
    // }
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} name={tableName} handleFilter={handleFilter} downloadExcel={downloadExcel} />
        <TableContainer style={{ margin: '10px' }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headCells={headCells}
              topCol={topCol}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.uid_data.email}
                    // selected={isItemSelected}
                    >
                      {headCells.map((col, index1) => {

                        return (

                          index1 === 0 ?
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              {row.uid_data.last_name + ", " + row.uid_data.first_name}
                            </TableCell>
                            : index1 === 1 ?
                              <TableCell component="th" id={labelId} scope="row" padding="none">
                                {row.uid_data.office_loc === 0 ? "UK" : row.uid_data.office_loc === 1 ? "EDC" :  row.uid_data.office_loc === 2 ? "SL" : "NA"}
                              </TableCell>
                              : index1 === 2 ?
                                <TableCell component="th" id={labelId} scope="row" padding="none">
                                  {row[col.id]}
                                </TableCell>
                                :
                                <TableCell align="right">{row[col.id]}</TableCell>
                        )

                      })}
                      {/* <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.uid_data.last_name + ", " + row.uid_data.first_name}
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.uid_data.office_loc === 0 ? "PT&D" : "EDC"}
                      </TableCell>
                      <TableCell align="right">{row[headCells[index].id]}</TableCell>
                      <TableCell align="right">{row[headCells[index].id]}</TableCell>
                      <TableCell align="right">{row[headCells[index].id]}</TableCell>
                      <TableCell align="right">{row.protein}</TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}
