import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import { withStyles, Dialog, AppBar, Toolbar, IconButton, Slide, DialogTitle, CircularProgress, Box, Badge } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import moment from "moment";
import { getCookie } from '../Helper/CookieHelper';
import SecuredDeliSch from './SecuredDeliSch';
import CloseIcon from '@material-ui/icons/Close';
import UserTaskMap from './UserTaskMap';
import TaskManager from './TaskManagers/TaskManager';
import { Button, DialogActions, DialogContent } from '@material-ui/core';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import TenderFeedbackRequest from './FeedbackRequest/TenderFeedbackRequest';
import { errorMessage } from '../Helper/ErrorMessage';
import SpecialRateTab from './IndividualRate/SpecialRateTab';
import ScrollToTop from "react-scroll-to-top";
import PostAddIcon from '@material-ui/icons/PostAdd';
import UserTimesheetReport from '../Reports/UserTimesheetReport/UserTimesheetReport';
// import WBSManager from './TaskManagers/WBSManager';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import ProjectUsersTasks from '../WorkHours/ProjectWorkload/ProjectUsersTasks';
import { getPrimaryColor } from '../Helper/CommonThings';
import WebIcon from '@material-ui/icons/Web';
import { CsvBuilder } from 'filefy';
import MainTenderSubTasks from '../TenderSubTasks/MainTenderSubTasks';
// import ProjectTaskCards from '../MainDashBoard/Tablayout/ProjectTasks/ProjectTaskCards/ProjectTaskCards';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DateChangeReqManage from '../DateChangeRequest/DateChangeReqManage';
import AdditionalHrsTabs from './AdditionalHrsTabs';

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

// var dialogmsg = "";
var submitdata = null;

var filterData = []

var customDialogData = {}

var allDataTabs = [3, 4, 5]

// var oneDone = false;
// var twoDone = false;
// var resour = [];

class SecuredWorks extends Component {

    constructor(props) {
        super()

        this.state = {
            data: null,
            dataFull: null,
            dataAll: null,
            rates: [],
            data2: [],
            open: false,
            isLoading: true,
            isLoading2: true,
            isLoading4: true,
            allData: 'null',
            openEdit: false,
            openCalc: false,
            openPDM: false,
            pdmDialData: {},
            key: 0,
            subproj: [],
            updateNeed: false,
            data1a: null,
            data6a: null,
            data1aFull: null,
            data6aFull: null,
            openNoti: false,
            hourRequestData: [],
            hourRequestPending: 0,
            userPdm: null,
            openIndiviRate: false,
            individualRateData: {},
            tabSelect: 1,
            data1a_act: [],
            data_act: [],
            allocationPDMUsertype: null,
            openFeedback: false,
            isStage1a: false,
            openDownloadReport: false,
            downloadReportData: {},
            openProjectReport: false,
            psrAuditStatus: 10,
            projectReportData: {},
            toEVPData: {},
            openEvp: false,
            additionalHoursRequest: null,
            additionalHoursRequestId: null,
            checkedEvpSnapshot: true,
            tableWidth: null,
            customDialogHelper: false,
            openTimesheetReport: false,
            selectedTender: {},
            fav_data: [],
            workloadProjectData: {},
            openWorkloadReport: false,
            dateRquestDialog: false,
            requestTimesheetReportData: null,
            openSubTender: false,
            subTenderData: null
        }
        this.tabRef = React.createRef()
        this.refreshCount = React.createRef()
        this.refreshData = this.refreshData.bind(this)
        this.updateCount = this.updateCount.bind(this)
    }

    componentDidMount() {
        // console.log(this.state.fav_data)
        // const tabSelect = parseInt(this.state.tabSelect)
        Service.get('/bb/api/tender/pdm/?uid=' + user.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(respro => {
                // oneDone = true
                // console.log(respro.data)
                this.setState({
                    userPdm: respro.data
                })
                // if (oneDone && twoDone) {

                //     this.processData(resour, tabSelect)
                // }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
        this.fetchOps(0);
    }

    fetchOps(full_access) {

        var url = ""

        var typesdata = []

        if (access.access.l4a.l4a_g) {
            typesdata.push("ohl")
        }

        if (access.access.l4a.l4a_h) {
            typesdata.push("cabling")
        }

        if (access.access.l4a.l4a_i) {
            typesdata.push("substation")
        }

        if (access.access.l4a.l4a_m) {
            typesdata.push("edc")
        }

        if (access.access.l4a.l4a_l) {
            typesdata.push("multi_utilities")
        }

        if (access.access.l4a.l4a_af) {
            typesdata.push("technical")
        }


        const tabSelect = parseInt(this.state.tabSelect)

        if (tabSelect === 1 || tabSelect === 4) {
            if (access.access.l4a && access.access.l4a.l4a_d && full_access === 1) {
                url = "/bb/api/tender/project/available_workbook/?filter_type=2"
            } else if (typesdata.length > 0 && full_access === 1) {
                url = "/bb/api/tender/project/available_workbook/?filter_type=1&filter_data=" + typesdata.toString()
            } else {
                url = "/bb/api/tender/project/available_workbook/?filter_type=0"
            }
        } else if (tabSelect === 0 || tabSelect === 3) {
            if (access.access.l4a && access.access.l4a.l4a_d && full_access === 1) {
                url = "/bb/api/tender/project/estimates_effort/?filter_type=2"
            } else if (typesdata.length > 0 && full_access === 1) {
                url = "/bb/api/tender/project/estimates_effort/?filter_type=1&filter_data=" + typesdata.toString()
            } else {
                url = "/bb/api/tender/project/estimates_effort/?filter_type=0"
            }
        } else if (tabSelect === 2 || tabSelect === 5) {
            if (access.access.l4a && access.access.l4a.l4a_d && full_access === 1) {
                url = "/bb/api/tender/project/design_complete/?filter_type=2"
            } else if (typesdata.length > 0 && full_access === 1) {
                url = "/bb/api/tender/project/design_complete/?filter_type=1&filter_data=" + typesdata.toString()
            } else {
                url = "/bb/api/tender/project/design_complete/?filter_type=0"
            }
        }

        if (tabSelect === 3 || tabSelect === 4 || tabSelect === 5) {
            Service.get2(url, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    // console.log(res.data, tabSelect)

                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        element.em_name = element.tenderbid_data.pdm_data ? element.tenderbid_data.pdm_data.last_name + ", " + element.tenderbid_data.pdm_data.first_name : null
                    }

                    // resour = res

                    // this.fetchOps2(resour)
                    // console.log(this.tabRef.current ? this.tabRef.current.offsetWidth : null)
                    // twoDone = true
                    // resour = res
                    this.processData(res, tabSelect)

                })
                .catch(error => {
                    // console.log(error)
                    errorMessage(this.props, error)

                });
        } else {
            Service.get(url, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    // console.log(res.data, tabSelect)

                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        element.em_name = element.tenderbid_data.pdm_data ? element.tenderbid_data.pdm_data.last_name + ", " + element.tenderbid_data.pdm_data.first_name : null
                    }

                    // resour = res

                    // this.fetchOps2(resour)
                    // console.log(this.tabRef.current ? this.tabRef.current.offsetWidth : null)
                    // twoDone = true
                    // resour = res
                    this.processData(res, tabSelect)

                })
                .catch(error => {
                    // console.log(error)
                    errorMessage(this.props, error)

                });

        }
    }

    processData(res, tabSelect) {
        if (tabSelect === 1) {
            this.setState({
                data: res.data,
                dataAll: res.data,
                isLoading: false,
                updateNeed: false,
                tableWidth: this.tabRef.current ? this.tabRef.current.offsetWidth : null
            }, () => {
                // this.hoursRequestPending(res.data, tabSelect)
            })

        } else if (tabSelect === 0) {
            this.setState({
                data1a: res.data,
                dataAll: res.data,
                isLoading: false,
                updateNeed: false,
                tableWidth: this.tabRef.current ? this.tabRef.current.offsetWidth : null
            }, () => {
                // this.hoursRequestPending(res.data, tabSelect)
            })

        } else if (tabSelect === 2) {
            this.setState({
                data6a: res.data,
                dataAll: res.data,
                isLoading: false,
                updateNeed: false,
                tableWidth: this.tabRef.current ? this.tabRef.current.offsetWidth : null
            }, () => {
                // this.hoursRequestPending(res.data, tabSelect)
            })
        } else if (tabSelect === 3) {
            this.setState({
                data1aFull: res.data,
                dataAll: res.data,
                isLoading: false,
                updateNeed: false,
                tableWidth: this.tabRef.current ? this.tabRef.current.offsetWidth : null
            }, () => {
                // this.hoursRequestPending(res.data, tabSelect)
            })
        } else if (tabSelect === 4) {
            this.setState({
                dataFull: res.data,
                dataAll: res.data,
                isLoading: false,
                updateNeed: false,
                tableWidth: this.tabRef.current ? this.tabRef.current.offsetWidth : null
            }, () => {
                // this.hoursRequestPending(res.data, tabSelect)
            })
        } else if (tabSelect === 5) {
            this.setState({
                data6aFull: res.data,
                dataAll: res.data,
                isLoading: false,
                updateNeed: false,
                tableWidth: this.tabRef.current ? this.tabRef.current.offsetWidth : null
            }, () => {
                // this.hoursRequestPending(res.data, tabSelect)
            })
        }
    }

    hoursRequestPending(data_to_ids, tabSelect) {
        var tender_ids = "0"
        for (let i = 0; i < data_to_ids.length; i++) {
            const element = data_to_ids[i];
            tender_ids += "," + element.id
        }
        Service.get("/bb/api/tender/hours/request/pending_count/?tender=" + tender_ids, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // alert(res.data.length)
                var data_p = this.state.data
                var data1a_p = this.state.data1a
                var data6a_p = this.state.data6a
                var dataFull_p = this.state.dataFull
                var data1aFull_p = this.state.data1aFull
                var data6aFull_p = this.state.data6aFull
                if (data_p) {
                    for (let i = 0; i < data_p.length; i++) {
                        const element = data_p[i];
                        if (!element.hours_req_pending || (data_to_ids.length === 1 && element.id === data_to_ids[0].id)) {
                            element.hours_req_pending = 0
                        }
                        for (let j = 0; j < res.data.length; j++) {
                            const element1 = res.data[j];
                            if (element.id === element1.tender_pid) {
                                element.hours_req_pending += 1
                            }
                        }
                    }
                }
                if (data1a_p) {
                    for (let i = 0; i < data1a_p.length; i++) {
                        const element = data1a_p[i];
                        if (!element.hours_req_pending || (data_to_ids.length === 1 && element.id === data_to_ids[0].id)) {
                            element.hours_req_pending = 0
                        }
                        for (let j = 0; j < res.data.length; j++) {
                            const element1 = res.data[j];
                            if (element.id === element1.tender_pid) {
                                element.hours_req_pending += 1
                            }
                        }
                    }
                }
                if (data6a_p) {
                    for (let i = 0; i < data6a_p.length; i++) {
                        const element = data6a_p[i];
                        if (!element.hours_req_pending || (data_to_ids.length === 1 && element.id === data_to_ids[0].id)) {
                            element.hours_req_pending = 0
                        }
                        for (let j = 0; j < res.data.length; j++) {
                            const element1 = res.data[j];
                            if (element.id === element1.tender_pid) {
                                element.hours_req_pending += 1
                            }
                        }
                    }
                }
                if (dataFull_p) {
                    for (let i = 0; i < dataFull_p.length; i++) {
                        const element = dataFull_p[i];
                        if (!element.hours_req_pending || (data_to_ids.length === 1 && element.id === data_to_ids[0].id)) {
                            element.hours_req_pending = 0
                        }
                        for (let j = 0; j < res.data.length; j++) {
                            const element1 = res.data[j];
                            if (element.id === element1.tender_pid) {
                                element.hours_req_pending += 1
                            }
                        }
                    }
                }
                if (data1aFull_p) {
                    for (let i = 0; i < data1aFull_p.length; i++) {
                        const element = data1aFull_p[i];
                        if (!element.hours_req_pending || (data_to_ids.length === 1 && element.id === data_to_ids[0].id)) {
                            element.hours_req_pending = 0
                        }
                        for (let j = 0; j < res.data.length; j++) {
                            const element1 = res.data[j];
                            if (element.id === element1.tender_pid) {
                                element.hours_req_pending += 1
                            }
                        }
                    }
                }
                if (data6aFull_p) {
                    for (let i = 0; i < data6aFull_p.length; i++) {
                        const element = data6aFull_p[i];
                        if (!element.hours_req_pending || (data_to_ids.length === 1 && element.id === data_to_ids[0].id)) {
                            element.hours_req_pending = 0
                        }
                        for (let j = 0; j < res.data.length; j++) {
                            const element1 = res.data[j];
                            if (element.id === element1.tender_pid) {
                                element.hours_req_pending += 1
                            }
                        }
                    }
                }

                if (tabSelect === 1) {
                    this.setState({
                        data: data_p,
                        // data1a: data1a_p,
                        // data6a: data6a_p
                    })
                } else if (tabSelect === 0) {
                    this.setState({
                        // data: data_p,
                        data1a: data1a_p,
                        // data6a: data6a_p
                    })
                } else if (tabSelect === 2) {
                    this.setState({
                        // data: data_p,
                        // data1a: data1a_p,
                        data6a: data6a_p
                    })
                } else if (tabSelect === 4) {
                    this.setState({
                        dataFull: dataFull_p,
                        // data1a: data1a_p,
                        // data6a: data6a_p
                    })
                } else if (tabSelect === 3) {
                    this.setState({
                        // data: data_p,
                        data1aFull: data1aFull_p,
                        // data6a: data6a_p
                    })
                } else if (tabSelect === 5) {
                    this.setState({
                        // data: data_p,
                        // data1a: data1a_p,
                        data6aFull: data6aFull_p
                    })
                }

            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    // fetchOps2(res) {
    //     var dataD = [];
    //     var toShw = '0';

    //     var indevwork = '0'
    //     var viewPid = [];
    //     var pids_ids = []

    //     for (let i = 0; i < res.data.length; i++) {
    //         const element = res.data[i];
    //         pids_ids.push(element.id)
    //         // console.log(this.state.userPdm)
    //         if (!element.is_subproject && element.tenderbid_data && element.tenderbid_data.lead_dicipline !== 'trail' && ((element.tenderbid_data.pdm === user.id
    //             || this.checkPDM(element.id, this.state.userPdm)) || (access && access.access && (access.access.l4a.l4a_d
    //                 || (access.access.l4a.l4a_g && element.tenderbid_data.lead_dicipline === 'ohl') || (access.access.l4a.l4a_h && element.tenderbid_data.lead_dicipline === 'cabling')
    //                 || (access.access.l4a.l4a_i && element.tenderbid_data.lead_dicipline === 'substation') nnned another discipline || (access.access.l4a.l4a_m && element.tenderbid_data.lead_dicipline === 'edc')
    //                 || (access.access.l4a.l4a_l && element.tenderbid_data.lead_dicipline === 'multi_utilities'))))) {
    //             // console.log(element)
    //             if (element.from_tenderbid) {
    //                 element.name = element.tender_name;
    //                 // console.log(element.tenderbid_data)
    //                 element.lead_dicipline = element.tenderbid_data.lead_dicipline;
    //                 dataD.push(element)

    //                 viewPid.push({ 'tender_pid': element.id })

    //                 toShw = toShw + ',' + element.id;
    //             }
    //             //  else if (user.is_admin) {
    //             //     dataD.push(element)
    //             //     toShw = toShw + ',' + element.id;
    //             // }

    //             for (let j = 0; j < res.data.length; j++) {
    //                 const element1 = res.data[j];
    //                 if (element1.subproject_pid && element.id === element1.subproject_pid) {
    //                     toShw = toShw + ',' + element1.id;
    //                 }
    //             }

    //         }
    //         // else if (!element.is_subproject && element.tenderbid_data && element.tenderbid_data.lead_dicipline === 'edc') {
    //         //     // console.log(element)
    //         //     if (element.from_tenderbid && (element.tenderbid_data.bid_lead === user.id || element.tenderbid_data.lde === user.id || element.tenderbid_data.pdm === user.id || element.tenderbid_data.lead_engineer === user.id)) {
    //         //         element.name = element.tender_name;
    //         //         dataD1.push(element)
    //         //     } else if (user.is_admin) {
    //         //         dataD1.push(element)
    //         //     }

    //         // }

    //         // if (element.is_subproject) {
    //         //     toShw = toShw + ',' + element.id;
    //         // }

    //         if (element.is_subproject && element.audit_status === 11) {

    //             toShw = toShw + ',' + element.id;

    //             var goon = true;
    //             for (let i = 0; i < res.data.length; i++) {
    //                 const element1 = res.data[i];
    //                 if (element.subproject_pid === element1.id) {
    //                     goon = false;
    //                     break;
    //                 }
    //             }
    //             if (goon) {

    //                 indevwork = indevwork + "," + element.subproject_pid
    //             }

    //         }
    //     }

    //     // console.log(indevwork)
    //     // 
    //     Service.get('/bb/api/tender/project/?id=' + indevwork, {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //     })
    //         .then(respro => {

    //             var data12 = []

    //             var resprodata = [...res.data, ...respro.data]


    //             for (let i = 0; i < resprodata.length; i++) {
    //                 const element = resprodata[i];

    //                 if (!element.is_subproject && element.tenderbid_data && element.tenderbid_data.lead_dicipline !== 'trail' && ((element.tenderbid_data.pdm === user.id || this.checkPDM(element.id, this.state.userPdm)) || (access && access.access && (access.access.l4a.l4a_d
    //                     || (access.access.l4a.l4a_g && element.tenderbid_data.lead_dicipline === 'ohl') || (access.access.l4a.l4a_h && element.tenderbid_data.lead_dicipline === 'cabling')
    //                     || (access.access.l4a.l4a_i && element.tenderbid_data.lead_dicipline === 'substation') || (access.access.l4a.l4a_m && element.tenderbid_data.lead_dicipline === 'edc')
    //                     || (access.access.l4a.l4a_l && element.tenderbid_data.lead_dicipline === 'multi_utilities'))))) {
    //                     data12.push(element)
    //                     element.lead_dicipline = element.tenderbid_data.lead_dicipline;
    //                     viewPid.push({ 'tender_pid': element.id })
    //                 }

    //             }

    //             this.setState({
    //                 data1a: data12,
    //                 data: dataD,
    //                 dataAll: res.data,
    //                 isLoading: false,
    //                 updateNeed: false,
    //             }, () => {
    //                 if (viewPid.length !== 0) {
    //                     // Service.patch("/bb/api/team/tasks/get_acthours/", {
    //                     //     headers: {
    //                     //         Authorization: "Token " + token,
    //                     //     },
    //                     //     data: viewPid,
    //                     // })
    //                     //     .then(res12 => {

    //                     //         for (let j = 0; j < res12.data.length; j++) {
    //                     //             const element13 = res12.data[j];
    //                     //             for (let i = 0; i < dataD.length; i++) {
    //                     //                 const element12 = dataD[i];
    //                     //                 if (element12.id === element13.id) {
    //                     //                     element12.actual_hours = timeToDecimal(element13.hours_actual)
    //                     //                     break;
    //                     //                 }
    //                     //                 // console.log(timeToDecimal(element13.hours_actual) !== 0)
    //                     //             }
    //                     //             for (let i = 0; i < data12.length; i++) {
    //                     //                 const element15 = data12[i];
    //                     //                 if (element15.id === element13.id) {
    //                     //                     element15.actual_hours = timeToDecimal(element13.hours_actual)
    //                     //                     break;
    //                     //                 }
    //                     //             }
    //                     //         }
    //                     //         // console.log(dataD)
    //                     //         this.setState({
    //                     //             data1a: data12,
    //                     //             data: dataD,

    //                     //         })
    //                     //     })
    //                     //     .catch(error => {
    //                     //         this.props.enqueueSnackbar('Hours utilized data unavailable.', {
    //                     //             variant: 'error'
    //                     //         });

    //                     //     });
    //                 }

    //             });

    //             Service.patch('/bb/api/tender/tasks/rolesnodata/get_data/', {
    //                 headers: {
    //                     Authorization: "Token " + token,
    //                 },
    //                 data: pids_ids
    //             })
    //                 .then(res1 => {
    //                     // console.log(res1.data)
    //                     this.setState({ data2: JSON.parse(JSON.stringify(res1.data)), isLoading4: false, });

    //                     this.additinalHourReqData();
    //                 })
    //                 .catch(error => {
    //                     errorMessage(this.props, error)
    //                 });

    //             Service.patch('/bb/api/tender/rate/get_data/', {
    //                 headers: {
    //                     Authorization: "Token " + token,
    //                 },
    //                 data: pids_ids
    //             })
    //                 .then(res1 => {
    //                     // console.log(res1.data)
    //                     this.setState({
    //                         isLoading2: false,
    //                         rates: res1.data,
    //                     });
    //                 })
    //                 .catch(error => {
    //                     errorMessage(this.props, error)
    //                 });

    //         })
    //         .catch(error => {
    //             errorMessage(this.props, error)
    //         });

    // }

    fetchActHours(rowData) {

        var data12 = this.state.data1a
        var data13 = this.state.data6a
        var dataD = this.state.data
        var data12Full = this.state.data1aFull
        var data13Full = this.state.data6aFull
        var dataDFull = this.state.dataFull

        if (this.state.tabSelect === 1) {

            for (let i = 0; i < dataD ? dataD.length : 0; i++) {
                const element = dataD[i];
                if (rowData.id === element.id) {
                    element.loading = true
                }
            }
            this.setState({
                data: dataD
            })

        } else if (this.state.tabSelect === 0) {
            for (let i = 0; i < data12 ? data12.length : 0; i++) {
                const element = data12[i];
                if (rowData.id === element.id) {
                    element.loading = true
                }
            }
            this.setState({
                data1a: data12
            })

        } else if (this.state.tabSelect === 2) {
            for (let i = 0; i < data13 ? data13.length : 0; i++) {
                const element = data13[i];
                if (rowData.id === element.id) {
                    element.loading = true
                }
            }
            this.setState({
                data6a: data13
            })
        } else if (this.state.tabSelect === 4) {

            for (let i = 0; i < dataDFull ? dataDFull.length : 0; i++) {
                const element = dataDFull[i];
                if (rowData.id === element.id) {
                    element.loading = true
                }
            }
            this.setState({
                dataFull: dataDFull
            })

        } else if (this.state.tabSelect === 3) {
            for (let i = 0; i < data12Full ? data12Full.length : 0; i++) {
                const element = data12Full[i];
                if (rowData.id === element.id) {
                    element.loading = true
                }
            }
            this.setState({
                data1aFull: data12Full
            })

        } else if (this.state.tabSelect === 5) {
            for (let i = 0; i < data13Full ? data13Full.length : 0; i++) {
                const element = data13Full[i];
                if (rowData.id === element.id) {
                    element.loading = true
                }
            }
            this.setState({
                data6aFull: data13Full
            })
        }

        var viewPid = [];
        viewPid.push({ 'tender_pid': rowData.id, "available_id": rowData.tender_ds_ids })

        var url = "/bb/api/tender/project/available_workbook/"

        var typesdata = []

        if (access.access.l4a.l4a_g) {
            typesdata.push("ohl")
        }

        if (access.access.l4a.l4a_h) {
            typesdata.push("cabling")
        }

        if (access.access.l4a.l4a_i) {
            typesdata.push("substation")
        }

        if (access.access.l4a.l4a_m) {
            typesdata.push("edc")
        }

        if (access.access.l4a.l4a_l) {
            typesdata.push("multi_utilities")
        }

        if (access.access.l4a.l4a_af) {
            typesdata.push("technical")
        }


        if (access.access.l4a && access.access.l4a.l4a_d && [3, 4, 5].includes(this.state.tabSelect)) {
            url = "/bb/api/team/tasks/get_acthours/?filter_type=all"
        } else {
            url = "/bb/api/team/tasks/get_acthours/?filter_type=0"
        }

        Service.patch(url, {
            headers: {
                Authorization: "Token " + token,
            },
            data: viewPid,
        })
            .then(res12 => {

                for (let j = 0; j < (res12.data ? res12.data.length : 0); j++) {
                    const element13 = res12.data[j];
                    if (this.state.tabSelect === 1) {

                        for (let i = 0; i < (dataD ? dataD.length : 0); i++) {
                            const element12 = dataD[i];
                            if (element12.id === element13.id) {
                                element12.actual_hours = timeToDecimal(element13.hours_actual)
                                break;
                            }
                        }

                    } else if (this.state.tabSelect === 0) {
                        for (let i = 0; i < (data12 ? data12.length : 0); i++) {
                            const element15 = data12[i];
                            if (element15.id === element13.id) {
                                element15.actual_hours = timeToDecimal(element13.hours_actual)
                                break;
                            }
                        }

                    } else if (this.state.tabSelect === 2) {
                        for (let i = 0; i < (data13 ? data13.length : 0); i++) {
                            const element15 = data13[i];
                            if (element15.id === element13.id) {
                                element15.actual_hours = timeToDecimal(element13.hours_actual)
                                break;
                            }
                        }
                    } else if (this.state.tabSelect === 4) {

                        for (let i = 0; i < (dataDFull ? dataDFull.length : 0); i++) {
                            const element12 = dataDFull[i];
                            if (element12.id === element13.id) {
                                element12.actual_hours = timeToDecimal(element13.hours_actual)
                                break;
                            }
                        }

                    } else if (this.state.tabSelect === 3) {
                        for (let i = 0; i < (data12Full ? data12Full.length : 0); i++) {
                            const element15 = data12Full[i];
                            if (element15.id === element13.id) {
                                element15.actual_hours = timeToDecimal(element13.hours_actual)
                                break;
                            }
                        }

                    } else if (this.state.tabSelect === 5) {
                        for (let i = 0; i < (data13Full ? data13Full.length : 0); i++) {
                            const element15 = data13Full[i];
                            if (element15.id === element13.id) {
                                element15.actual_hours = timeToDecimal(element13.hours_actual)
                                break;
                            }
                        }
                    }

                }

                if (this.state.tabSelect === 1) {
                    this.setState({
                        data: dataD,
                    })
                } else if (this.state.tabSelect === 0) {
                    this.setState({
                        data1a: data12,
                    })
                } else if (this.state.tabSelect === 2) {
                    this.setState({
                        data6a: data13
                    })
                } else if (this.state.tabSelect === 4) {
                    this.setState({
                        dataFull: dataDFull,
                    })
                } else if (this.state.tabSelect === 3) {
                    this.setState({
                        data1aFull: data12Full,
                    })
                } else if (this.state.tabSelect === 5) {
                    this.setState({
                        data6aFull: data13Full
                    })
                }

            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                    variant: 'error'
                });

            });
    }

    checkPDM(id, list) {
        for (let i = 0; i < list.length; i++) {
            const element = list[i];
            if (element.pid === id) {
                return true;
            }
        }
        return false;
    }

    // additinalHourReqData() {
    //     Service.get("/bb/api/tender/hours/request/pending_count/?tender=" + user.id, {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //     })
    //         .then(res => {
    //             // alert(res.data.length)

    //         })
    //         .catch(error => {
    //             // console.log(error)
    //             errorMessage(this.props, error)
    //         });
    // }

    giveaccess() {

        var json = {
            email: submitdata.email,
            is_staff: true,
            is_lead: true,
            is_admin: !submitdata.is_admin,
        }

        Service.put("/bb/api/tender/project/" + submitdata.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: json,
        })
            .then(res => {

                this.setState({ open: false, });
                // console.log(res.data)
                this.props.enqueueSnackbar('Permission changed for ' + submitdata.email, {
                    variant: 'success'
                });
                this.fetchOps(0);
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    // costCalc(rowData) {
    //     var cost = 0;
    //     for (let i = 0; i < this.state.data2.length; i++) {
    //         const element = this.state.data2[i];
    //         if (element.t_pid === rowData.id) {
    //             cost = cost + this.costCalc2(element)
    //         }
    //     }
    //     for (let i = 0; i < this.state.dataAll.length; i++) {
    //         const element = this.state.dataAll[i];
    //         // console.log(element.is_subproject +'   '+ element.subproject_pid +'   '+ rowData.id)
    //         if (element.is_subproject && element.subproject_pid === rowData.id) {
    //             for (let j = 0; j < this.state.data2.length; j++) {
    //                 const element1 = this.state.data2[j];
    //                 if (element1.t_pid === element.id) {
    //                     cost = cost + this.costCalc2(element1)
    //                 }
    //             }
    //         }
    //     }
    //     return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(cost);
    // }

    // costCalc2(data1) {
    //     if (data1.country === 'in') {
    //         for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
    //             const element = this.state.rates[0].t_rates[i];
    //             if (data1.grade.toUpperCase() === element.grade) {
    //                 // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
    //                 return data1.hours * element.costin;
    //             }
    //         }
    //     } else {
    //         for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
    //             const element = this.state.rates[0].t_rates[i];
    //             if (data1.grade.toUpperCase() === element.grade) {
    //                 return data1.hours * element.costuk;
    //             }
    //         }
    //     }
    //     return 0;
    // }

    // hourCalc(rowData, audit_status) {
    //     var cost = 0;
    //     for (let i = 0; i < this.state.data2.length; i++) {
    //         const element = this.state.data2[i];
    //         if (element.t_pid === rowData.id && element.audit_status === audit_status) {
    //             cost = cost + parseFloat(element.hours);
    //         }
    //     }
    //     for (let i = 0; i < this.state.dataAll.length; i++) {
    //         const element = this.state.dataAll[i];
    //         // console.log(element.is_subproject +'   '+ element.subproject_pid +'   '+ rowData.id)
    //         if (element.is_subproject && element.subproject_pid === rowData.id && element.audit_status === audit_status) {
    //             for (let j = 0; j < this.state.data2.length; j++) {
    //                 const element1 = this.state.data2[j];
    //                 if (element1.t_pid === element.id) {
    //                     cost = cost + parseFloat(element1.hours);
    //                 }
    //             }
    //         }
    //     }
    //     return cost.toFixed(2);
    // }

    // hourCalc2(rowData, audit_status) {
    //     var cost = 0;
    //     for (let i = 0; i < this.state.data2.length; i++) {
    //         const element = this.state.data2[i];
    //         if (element.t_pid === rowData.id && element.audit_status === audit_status) {
    //             cost = cost + parseFloat(element.hours) + parseFloat(element.req_hours);
    //         }
    //     }
    //     for (let i = 0; i < this.state.dataAll.length; i++) {
    //         const element = this.state.dataAll[i];
    //         // console.log(element.is_subproject +'   '+ element.subproject_pid +'   '+ rowData.id)
    //         if (element.is_subproject && element.subproject_pid === rowData.id && element.audit_status === audit_status) {
    //             for (let j = 0; j < this.state.data2.length; j++) {
    //                 const element1 = this.state.data2[j];
    //                 if (element1.t_pid === element.id) {
    //                     cost = cost + parseFloat(element1.hours) + parseFloat(element1.req_hours);
    //                 }
    //             }
    //         }
    //     }
    //     return cost.toFixed(2);
    // }

    dateParse(date) {
        var formatDate = moment(date).format('DD/MM/YYYY');
        // console.log(formatDate)
        if (formatDate === 'Invalid date') {
            return 'NA';
        } else {
            return formatDate;
        }
    }

    allocationPDMAccess(rowData) {
        // console.log(rowData, this.state.userPdm)
        for (let pdmData = 0; pdmData < this.state.userPdm.length; pdmData++) {
            const element = this.state.userPdm[pdmData];
            if (element.pid === rowData.id && element.allocation_access) {
                return true;
            }
        }
        return false;
    }

    allocationPDMAccessType(rowData) {
        // return 'ohl_civ_struc'
        if (rowData.tenderbid_data.pdm === user.id) {
            return null;
        } else {
            for (let pdmData = 0; pdmData < this.state.userPdm.length; pdmData++) {
                const element = this.state.userPdm[pdmData];
                if (element.pid === rowData.id && element.allocation_access === 1) {
                    return element;
                } else if (element.pid === rowData.id && element.allocation_access === 3) {
                    return element;
                } else if (element.pid === rowData.id && element.allocation_access === 2) {
                    return null;
                }
            }
            return null;
        }

    }

    checkAccessDecipline(element) {
        if ((access.access.l4a.l4a_n && element.tenderbid_data.lead_dicipline === 'ohl') || (access.access.l4a.l4a_o && element.tenderbid_data.lead_dicipline === 'cabling')
            || (access.access.l4a.l4a_p && element.tenderbid_data.lead_dicipline === 'substation')
            || (access.access.l4a.l4a_t && element.tenderbid_data.lead_dicipline === 'edc') || (access.access.l4a.l4a_ag && element.tenderbid_data.lead_dicipline === 'multi_utilities')
            || (access.access.l4a.l4a_s && element.tenderbid_data.lead_dicipline === 'technical')) {
            return true;
        }
        return false
    }

    openResourceAllocation(rowData) {
        if (rowData.tenderbid_data.pdm) {
            this.setState({ allData: rowData, openCalc: true, allocationPDMUsertype: this.allocationPDMAccessType(rowData), isStage1a: false, })

        } else {
            this.props.enqueueSnackbar('Please assign a EM to the project before Resource Allocation!', {
                variant: 'error'
            });
        }
    }

    openResourceAllocation2(rowData) {
        if (rowData.tenderbid_data.pdm) {
            this.setState({ allData: rowData, openCalc: true, isStage1a: true, })
        } else {
            this.props.enqueueSnackbar('Please assign a EM to the project before Resource Allocation!', {
                variant: 'error'
            });
        }
    }

    findFilterValue(field) {
        for (let i = 0; i < filterData.length; i++) {
            const element = filterData[i];
            // console.log(field === element.column.field)
            if (field === element.column.field) {
                return element.value
            }
        }
        return null
    }

    callAPI() {
        if (!this.state.data && this.state.tabSelect === 1) {
            this.fetchOps(0)

        } else if (!this.state.data1a && this.state.tabSelect === 0) {
            this.fetchOps(0)

        } else if (!this.state.data6a && this.state.tabSelect === 2) {
            this.fetchOps(0)
        } else if (!this.state.dataFull && this.state.tabSelect === 4) {
            this.fetchOps(1)
        } else if (!this.state.data1aFull && this.state.tabSelect === 3) {
            this.fetchOps(1)
        } else if (!this.state.data6aFull && this.state.tabSelect === 5) {
            this.fetchOps(1)
        } else {
            // alert("0")
            setTimeout(() => this.setState({
                isLoading: false
            }), 500)

        }
    }

    customDialogOpenForTimesheetReport(data) {
        customDialogData.title = "Timesheet Report for " + data.tender_name
        customDialogData.body = "Do you want generate report the project?.\nReport will be mailed to you after few minutes."
        customDialogData.positive = "Yes"
        customDialogData.negative = 'No'
        this.setState({
            customDialogHelper: true
        })
    }

    addFavourite(value) {
        if (value.tender_project_user_pref_id) {
            var put_data = {
                tender_project: value.id,
                is_favourite: !value.is_favourite,
                user: user.id,
                created_by: user.id
            }
            Service.put("/bb/api/tender/project/user/pref/" + value.tender_project_user_pref_id + "/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: put_data,
            })
                .then(res => {
                    this.reRenderFav(value, res.data)
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        } else {
            var initial_post_data = {
                tender_project: value.id,
                is_favourite: !value.is_favourite,
                user: user.id,
                created_by: user.id
            }
            Service.post('/bb/api/tender/project/user/pref/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: initial_post_data,
            })
                .then(res => {
                    this.reRenderFav(value, res.data)
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
    }

    reRenderFav(value, response) {
        var stateKey = 'data'
        if (this.state.tabSelect === 1) {
            stateKey = 'data'
        } else if (this.state.tabSelect === 0) {
            stateKey = 'data1a'
        } else if (this.state.tabSelect === 2) {
            stateKey = 'data6a'
        } else if (this.state.tabSelect === 3) {
            stateKey = 'data1aFull'
        } else if (this.state.tabSelect === 4) {
            stateKey = 'dataFull'
        } else if (this.state.tabSelect === 5) {
            stateKey = 'data6aFull'
        }
        var dataToChange = this.state[stateKey]
        for (let i = 0; i < dataToChange.length; i++) {
            const element = dataToChange[i];
            if (element.id === response.tender_project) {
                element.tender_project_user_pref_id = response.id
                element.is_favourite = response.is_favourite
                break
            }
        }
        dataToChange.sort(function (a, b) { return b.is_favourite - a.is_favourite });
        // console.log(dataToChange)
        this.setState({
            [stateKey]: dataToChange
        })
    }

    refreshData(updateCount) {
        if (this.state.data !== null) {
            let data = this.state.data
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                if (element.id === updateCount.tender) {
                    element.pending_date_request = updateCount.pending_date_request
                    element.pending_hrs_request = updateCount.pending_hrs_request
                    element.pending_task_allocation_request = updateCount.pending_task_allocation_request
                    break
                }
            }
            this.setState({ data }, () => {
                if (this.refreshCount && this.refreshCount.current) {
                    this.refreshCount.current.fetchOps()
                }
            })
        }

        if (this.state.dataFull !== null) {
            let dataFull = this.state.dataFull
            for (let i = 0; i < dataFull.length; i++) {
                const element = dataFull[i];
                if (element.id === updateCount.tender) {
                    element.pending_date_request = updateCount.pending_date_request
                    element.pending_hrs_request = updateCount.pending_hrs_request
                    element.pending_task_allocation_request = updateCount.pending_task_allocation_request
                    break
                }
            }
            this.setState({ dataFull }, () => {
                if (this.refreshCount && this.refreshCount.current) {
                    this.refreshCount.current.fetchOps()
                }
            })
        }

        if (this.state.data1a !== null) {
            let data1a = this.state.data1a
            for (let i = 0; i < data1a.length; i++) {
                const element = data1a[i];
                if (element.id === updateCount.tender) {
                    element.pending_date_request = updateCount.pending_date_request
                    element.pending_hrs_request = updateCount.pending_hrs_request
                    element.pending_task_allocation_request = updateCount.pending_task_allocation_request
                    break
                }
            }
            this.setState({ data1a }, () => {
                if (this.refreshCount && this.refreshCount.current) {
                    this.refreshCount.current.fetchOps()
                }
            })
        }

        if (this.state.data1aFull !== null) {
            let data1aFull = this.state.data1aFull
            for (let i = 0; i < data1aFull.length; i++) {
                const element = data1aFull[i];
                if (element.id === updateCount.tender) {
                    element.pending_date_request = updateCount.pending_date_request
                    element.pending_hrs_request = updateCount.pending_hrs_request
                    element.pending_task_allocation_request = updateCount.pending_task_allocation_request
                    break
                }
            }
            this.setState({ data1aFull }, () => {
                if (this.refreshCount && this.refreshCount.current) {
                    this.refreshCount.current.fetchOps()
                }
            })
        }
    }

    updateCount(updateCount) {
        var url = `/bb/api/estimate/date/request/get_pending_count/?&tender_id=${updateCount.tender}`

        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.refreshData(res.data)
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    updateHrsCount() {
        var url = `/bb/api/estimate/date/request/get_pending_count/?&tender_id=${this.state.additionalHoursRequest.id}`

        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.refreshData(res.data)
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }
    openSubTender(data) {
        this.setState({ subTenderData: data }, () => { this.setState({ openSubTender: true }) })
    }

    render() {
        const { classes } = this.props;

        const handleSubTenderClose = () => {
            this.setState({ openSubTender: false, subTenderData: null })
        }
        const handleClose2 = () => {
            this.setState({ openCalc: false, });
        };

        const handleClose5 = () => {
            this.setState({ openIndiviRate: false, });
        };

        const handleClose = () => {
            this.setState({ openPDM: false, });
        };

        const handleNotiClose = () => {
            this.setState({ openNoti: false, additionalHoursRequestId: null });
            // this.hoursRequestPending([this.state.additionalHoursRequest], this.state.tabSelect)
            this.updateHrsCount(this.state.additionalHoursRequest)
        };

        const handleFeedbackClose = () => {
            this.setState({ openFeedback: false, });

        };

        const handleTabChange = (event, newValue) => {
            if (newValue !== this.state.tabSelect) {
                if (newValue < 6) {
                    this.setState({
                        tabSelect: newValue,
                        isLoading: true
                    }, () => this.callAPI())
                } else {
                    this.setState({
                        tabSelect: newValue,

                    })
                }

            }

        };

        const handleDialogClose = () => {
            this.setState({
                openTimesheetReport: false
            })
        };

        const handleWorkloadClose = () => {
            this.setState({
                openWorkloadReport: false
            })
        };

        const handleDateChangeReqClose = () => {
            this.setState({
                dateRquestDialog: false
            })
        };

        const exportCsv = (columnList, initialData) => {
            const columns = columnList.filter(columnDef => {
                return !columnDef.hidden && columnDef.field && columnDef.export !== false && !columnDef.nonExport;
            });

            const data = initialData.map(rowData =>
                columns.map(columnDef => {
                    if (columnDef.customExport === 'in_key') {
                        var curr_data = rowData
                        var keys = columnDef.field.split(".")
                        for (let i = 0; i < keys.length; i++) {
                            const element = keys[i];
                            curr_data = curr_data[element]
                        }
                        return curr_data
                    } else if (columnDef.customExport === 'hold') {
                        return rowData.task_hold ? "Hold" : "Unhold"
                    } else if (columnDef.customExport === 'billable') {
                        return rowData.inc_evp ? "Billable" : "Not Billable"
                    }
                    return columnDef.render ? columnDef.render(rowData) : columnDef.lookup ? columnDef.lookup[rowData[columnDef.field]] : rowData[columnDef.field];
                })
            );

            const builder = new CsvBuilder("workbook-" + moment().format('DD-MM-YYYY hh:mm') + '.csv');
            builder
                .setDelimeter(',')
                .setColumns(columns.map(columnDef => columnDef.title))
                .addRows(data)
                .exportFile();
        };

        return (
            <div>
                <ScrollToTop smooth />
                <AppBar position="static" color="default" ref={this.tabRef}>
                    {(access.access.l4a && (access.access.l4a.l4a_d || access.access.l4a.l4a_g || access.access.l4a.l4a_h || access.access.l4a.l4a_i || access.access.l4a.l4a_m || access.access.l4a.l4a_l || access.access.l4a.l4a_af)) ? (
                        <Tabs
                            value={this.state.tabSelect}
                            onChange={handleTabChange}
                            scrollButtons="auto"
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            aria-label="tabs"
                        >
                            <Tab label={"My Estimates Effort"} />
                            <Tab label={"My Available Workbook"} />
                            <Tab label={"My Design Complete"} />
                            <Tab label="All Estimates Effort" />
                            <Tab label="All Available Workbook" />
                            <Tab label="All Design Complete" />
                        </Tabs>

                    ) : (
                        <Tabs
                            value={this.state.tabSelect}
                            onChange={handleTabChange}
                            scrollButtons="auto"
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="tabs"
                        >
                            <Tab label={"Estimates Effort"} />
                            <Tab label={"Available Workbook"} />
                            <Tab label={"Design Complete"} />
                        </Tabs>
                    )}
                </AppBar>
                {(this.state.isLoading || this.state.userPdm === null) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div style={{ display: 'flex' }}>

                        {(this.state.tabSelect === 1 || this.state.tabSelect === 4) ? (
                            <MaterialTable
                                style={{ minWidth: this.state.tableWidth }}
                                title={user.last_name + ", " + user.first_name + "'s Available Workbook"}
                                columns={[
                                    {
                                        title: 'Discipline', field: 'tenderbid_data.lead_dicipline', nonExport: true,
                                        headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                                        defaultFilter: this.findFilterValue("tenderbid_data.lead_dicipline"),
                                        lookup: { 'ohl': 'OHL', 'cabling': 'Cabling', 'substation': 'Substation', 'multi_utilities': 'Multi Utilities', 'technical': 'Technical', 'edc': 'EDC' }
                                    },
                                    {
                                        title: 'Project', field: 'tender_name',
                                        defaultFilter: this.findFilterValue("tender_name"),
                                        headerStyle: { minWidth: 300 }, cellStyle: { minWidth: 300 },
                                    }, {
                                        title: 'Created Date', field: 'date_created', type: 'date',
                                        render: rowData => moment(rowData.date_created).format('DD/MM/YYYY')
                                    },
                                    {
                                        title: 'EM', field: 'em_name',
                                        headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                                        // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.pdm_data) ? (rowData.tenderbid_data.pdm_data.last_name + ", " + rowData.tenderbid_data.pdm_data.first_name) : (null),
                                        defaultFilter: this.findFilterValue("em_name"),
                                        // customFilterAndSearch: (term, rowData) => {
                                        //     return ((rowData.tenderbid_data && rowData.tenderbid_data.pdm_data) ? (rowData.tenderbid_data.pdm_data.last_name + ", " + rowData.tenderbid_data.pdm_data.first_name) : (null)).toLowerCase().includes(term.toLowerCase())
                                        // }
                                    },

                                    {
                                        title: 'Client', field: 'tenderbid_data.client_name',
                                        defaultFilter: this.findFilterValue("tenderbid_data.client_name"),
                                        // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                    },
                                    // {
                                    //     title: 'Region', field: 'tenderbid_data.region_name',
                                    //     defaultFilter: this.findFilterValue("tenderbid_data.region_name"),
                                    //     // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                    // },

                                    {
                                        title: 'HoE', field: 'tenderbid_data.region_hoe.first_name',
                                        headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                                        render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.region_hoe) ? (rowData.tenderbid_data.region_hoe.last_name + ", " + rowData.tenderbid_data.region_hoe.first_name) : (null),
                                        defaultFilter: this.findFilterValue("tenderbid_data.region_hoe.first_name"),
                                        // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                    },

                                    // {
                                    //     title: 'Baseline Finish', field: 'to_date',
                                    //     render: rowData => this.dateParse(rowData.to_date)
                                    // },

                                    {
                                        title: 'Estimated Finish', field: 'to_date',
                                        render: rowData => this.dateParse(rowData.to_date),
                                        defaultFilter: this.findFilterValue("to_date"),
                                        customFilterAndSearch: (term, rowData) => {
                                            return this.dateParse(rowData.to_date).toLowerCase().includes(term.toLowerCase())
                                        }
                                    },

                                    {
                                        title: 'Budget Hours', field: 'budget_hours',
                                        defaultFilter: this.findFilterValue("budget_hours"),
                                        // render: rowData => this.hourCalc(rowData, 10),
                                        // customFilterAndSearch: (term, rowData) => {
                                        //     return rowData.budget_hours.toLowerCase().includes(term.toLowerCase())
                                        // }

                                    },
                                    {
                                        title: 'Hours to date', field: 'dummy', nonExport: true,
                                        render: rowData => (rowData.actual_hours || rowData.actual_hours === 0) ? (<Typography>{rowData.actual_hours}</Typography>) : ((!rowData.loading) ? <Button size="small" color="primary" onClick={() => { this.fetchActHours(rowData) }} >Fetch</Button> : <CircularProgress style={{ height: '15px', width: '15px' }} />),
                                        filtering: false
                                    },
                                    {
                                        title: "Hours to come", field: 'dummy', nonExport: true,
                                        render: rowData => (rowData.actual_hours || rowData.actual_hours === 0) ? (<Typography>{((rowData.revised_hours - rowData.actual_hours) < 0) ? (0) : (rowData.revised_hours - rowData.actual_hours)}</Typography>) : ((!rowData.loading) ? <Button size="small" color="primary" onClick={() => { this.fetchActHours(rowData) }} >Fetch</Button> : <CircularProgress style={{ height: '15px', width: '15px' }} />),
                                        filtering: false
                                    },
                                    {
                                        title: 'Hours at completion', field: 'revised_hours',
                                        defaultFilter: this.findFilterValue("revised_hours"),
                                        // render: rowData => <Typography>{this.hourCalc2(rowData, 10)}</Typography>
                                    },
                                    // {
                                    //     title: '% Complete', filed: 'dummy',
                                    //     render: rowData => <Typography></Typography>
                                    // },

                                ]}
                                data={this.state.tabSelect === 1 ? (this.state.data ? this.state.data : []) : (this.state.dataFull ? this.state.dataFull : [])}
                                options={{
                                    paging: false,
                                    padding: "dense",
                                    search: false,
                                    // padding: this.state.data.length < 7 ? "default" : "dense",
                                    filtering: true,
                                    doubleHorizontalScroll: true,
                                    pageSize: this.state.data ? this.state.data.length : 0,
                                    pageSizeOptions: [10, 20, 50],
                                    exportCsv: (columns, data) => {
                                        exportCsv(columns, data)
                                    },
                                    exportButton: { csv: true },
                                    // toolbar: false
                                }}
                                actions={[
                                    rowData => ({
                                        icon: () => rowData.is_favourite ? <StarIcon color='secondary' /> : <StarBorderIcon color='secondary' />,
                                        tooltip: rowData.is_favourite ? "Remove Favourite" : "Add Favourite",
                                        onClick: (event, rowData) => this.addFavourite(rowData),
                                    }),

                                    rowData => ({
                                        icon: () => <WebIcon />,
                                        tooltip: 'Planner',
                                        onClick: (event, rowData) => this.openSubTender(rowData),
                                    }),

                                    rowData => ({
                                        icon: 'feedback',
                                        tooltip: 'Feedback',
                                        hidden: !(((rowData.tenderbid_data.pdm === user.id) && (access && access.access && access.access.l4a.l4a_f)) || (access && access.access && access.access.l4a.l4a_e) || this.checkAccessDecipline(rowData)),
                                        onClick: (event, rowData) => { this.setState({ openFeedback: true, pdmDialData: rowData }) },
                                    }),
                                    rowData => ({
                                        icon: 'call_split',
                                        tooltip: 'Resource Allocation',
                                        hidden: !(((rowData.tenderbid_data.pdm === user.id || this.allocationPDMAccess(rowData)) && (access && access.access && access.access.l4a.l4a_c)) || (access && access.access && access.access.l4a.l4a_e) || this.checkAccessDecipline(rowData)),
                                        onClick: (event, rowData) => { this.openResourceAllocation(rowData) },
                                    }),

                                    rowData => ({
                                        icon: 'emoji_people',
                                        tooltip: "Project Access",
                                        hidden: !((rowData.tenderbid_data.pdm === user.id && (access && access.access && access.access.l4a.l4a_c)) || (access && access.access && access.access.l4a.l4a_e) || this.checkAccessDecipline(rowData)),
                                        onClick: (event, rowData) => { this.setState({ openPDM: true, pdmDialData: rowData }) },
                                    }),
                                    rowData => ({
                                        icon: 'credit_card',
                                        tooltip: "Special Rate",
                                        hidden: !(((rowData.tenderbid_data.pdm === user.id)) || (access && access.access && access.access.l4a.l4a_z)),
                                        onClick: (event, rowData) => { this.setState({ openIndiviRate: true, individualRateData: rowData }) },
                                    }),
                                    rowData => ({
                                        icon: 'visibility',
                                        tooltip: "PSR",
                                        hidden: !((rowData.psr && ((access && access.access && access.access.l4a.l4a_u) || (access && access.access && access.access.l4a.l4a_v))) || (access && access.access && access.access.l4a.l4a_ab)),
                                        onClick: (event, rowData) => {
                                            window.open("/report/psr/" + rowData.id + "/10/")
                                        },
                                    }),
                                    rowData => ({
                                        icon: 'compare',
                                        tooltip: "MCR",
                                        hidden: !((rowData.psr && ((rowData.tenderbid_data.pdm === user.id && (access && access.access && access.access.l4a.l4a_u)) || (access && access.access && access.access.l4a.l4a_v))) || (access && access.access && access.access.l4a.l4a_ab)),
                                        onClick: (event, rowData) => { window.open("/report/mcr/" + rowData.id) },
                                    }),
                                    rowData => ({
                                        icon: 'equalizer',
                                        tooltip: "EVP",
                                        hidden: !((rowData.evp && ((access && access.access && access.access.l4a.l4a_x) || (access && access.access && access.access.l4a.l4a_y))) || (access && access.access && access.access.l4a.l4a_z)),
                                        onClick: (event, rowData) => { window.open("/report/evp/" + rowData.id) },
                                    }),
                                    rowData => ({
                                        icon: 'watch_later',
                                        tooltip: "Workload Report",
                                        hidden: !(((rowData.tenderbid_data.pdm === user.id || this.allocationPDMAccess(rowData)) && (access && access.access && access.access.l4a.l4a_c)) || (access && access.access && access.access.l4a.l4a_e) || this.checkAccessDecipline(rowData)),
                                        onClick: (event, rowData) => { this.setState({ workloadProjectData: rowData, openWorkloadReport: true }) },
                                    }),
                                    rowData => ({
                                        icon: 'feed',
                                        tooltip: "Timesheet Report",
                                        onClick: (event, rowData) => {
                                            this.setState({
                                                selectedTender: rowData,
                                                openTimesheetReport: true
                                            })
                                        },
                                    }),
                                    rowData => ({
                                        icon: () => <Badge style={{ marginRight: '7px' }} max={9} badgeContent={rowData.pending_hrs_request + rowData.pending_task_allocation_request} color="primary">
                                            <PostAddIcon />
                                        </Badge>,
                                        tooltip: "Hours Request",
                                        hidden: !((rowData.tenderbid_data.pdm === user.id || rowData.enable_hours_request) || (access.access && access.access.l4a.l4a_ad)),
                                        onClick: (event, rowData) => { this.setState({ openNoti: true, additionalHoursRequestId: rowData.id, additionalHoursRequest: rowData }) },
                                    }),
                                    rowData => ({
                                        icon: () => <Badge style={{ marginRight: '7px' }} max={9} badgeContent={rowData.pending_date_request} color="primary">
                                            <DateRangeIcon />
                                        </Badge>,
                                        tooltip: "DateChange Request",
                                        hidden: !((rowData.tenderbid_data.pdm === user.id) || (rowData.tenderbid_data.region_hoe.id === user.id)),
                                        onClick: (event, rowData) => { this.setState({ dateRquestDialog: true, requestTimesheetReportData: rowData }) },
                                    }),

                                    // {
                                    //     icon: () => <Badge badgeContent={this.state.hourRequestPending} {...defaultProps} />,
                                    //     tooltip: "Hours Requests",
                                    //     isFreeAction: true,
                                    //     onClick: () => { this.setState({ openNoti: true, additionalHoursRequestId: null }) },
                                    // },

                                ]}
                                onFilterChange={(filter) => { filterData = filter }}
                                detailPanel={[
                                    {
                                        tooltip: 'Deliverables Schedule',
                                        render: rowData => {
                                            return (
                                                <SecuredDeliSch ref={this.refreshCount} updateCount={this.updateCount} value={rowData} onReload={() => this.setState({ updateNeed: true })} projs={this.state.dataAll} roles={this.state.data2} rates={this.state.rates} audit_status={10} tabSelected={this.state.tabSelect} allDataTabs={allDataTabs} />
                                            )
                                        }
                                    }]}
                            />
                        ) : (null)}

                        {(this.state.tabSelect === 0 || this.state.tabSelect === 3) ? (
                            <MaterialTable
                                style={{ minWidth: this.state.tableWidth }}
                                title={user.last_name + ", " + user.first_name + "'s Estimates Effort"}
                                columns={[
                                    {
                                        title: 'Discipline', field: 'tenderbid_data.lead_dicipline', nonExport: true,
                                        headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                                        defaultFilter: this.findFilterValue("tenderbid_data.lead_dicipline"),
                                        lookup: { 'ohl': 'OHL', 'cabling': 'Cabling', 'substation': 'Substation', 'multi_utilities': 'Multi Utilities', 'technical': 'Technical', 'edc': 'EDC' }
                                    },
                                    {
                                        title: 'Project', field: 'tender_name',
                                        defaultFilter: this.findFilterValue("tender_name"), headerStyle: { minWidth: 300 }, cellStyle: { minWidth: 300 },
                                    }, {
                                        title: 'Created Date', field: 'date_created', type: 'date',
                                        render: rowData => moment(rowData.date_created).format('DD/MM/YYYY')
                                    },
                                    {
                                        title: 'EM', field: 'em_name',
                                        headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                                        // headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                                        // defaultFilter: this.findFilterValue("pdm"),
                                        // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.pdm_data) ? (rowData.tenderbid_data.pdm_data.last_name + ", " + rowData.tenderbid_data.pdm_data.first_name) : (null)
                                    },
                                    {
                                        title: 'Client', field: 'tenderbid_data.client_name',
                                        defaultFilter: this.findFilterValue("tenderbid_data.client_name"),
                                        // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                    },
                                    // {
                                    //     title: 'Region', field: 'tenderbid_data.region_name',
                                    //     defaultFilter: this.findFilterValue("tenderbid_data.region_name"),
                                    //     // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                    // },
                                    {
                                        title: 'HoE', field: 'tenderbid_data.region_hoe.first_name',
                                        headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                                        render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.region_hoe) ? (rowData.tenderbid_data.region_hoe.last_name + ", " + rowData.tenderbid_data.region_hoe.first_name) : (null),
                                        defaultFilter: this.findFilterValue("tenderbid_data.region_hoe.first_name"),
                                        // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                    },
                                    // {
                                    //     title: 'Baseline Finish', field: 'to_date',
                                    //     render: rowData => this.dateParse(rowData.to_date)
                                    // },

                                    {
                                        title: 'Estimated Finish', field: 'to_date',
                                        defaultFilter: this.findFilterValue("to_date"),
                                        render: rowData => this.dateParse(rowData.to_date)
                                    },

                                    {
                                        title: 'Budget Hours', field: 'budget_hours',
                                        defaultFilter: this.findFilterValue("budget_hours"),
                                        // render: rowData => <Typography>{this.hourCalc(rowData, 11)}</Typography>
                                    },
                                    {
                                        title: 'Hours to date', field: 'dummy', nonExport: true,
                                        render: rowData => (rowData.actual_hours || rowData.actual_hours === 0) ? (rowData.actual_hours) : ((!rowData.loading) ? <Button size="small" color="primary" onClick={() => { this.fetchActHours(rowData) }} >Fetch</Button> : <CircularProgress style={{ height: '15px', width: '15px' }} />),
                                        customFilterAndSearch: (term, rowData) => {
                                            return rowData.actual_hours.includes(term.toLowerCase())
                                        },
                                        filtering: false
                                    },
                                    {
                                        title: "Hours to come", field: 'dummy', nonExport: true,
                                        render: rowData => (rowData.actual_hours || rowData.actual_hours === 0) ? (((rowData.revised_hours - rowData.actual_hours) < 0) ? (0) : (rowData.revised_hours - rowData.actual_hours)) : ((!rowData.loading) ? <Button size="small" color="primary" onClick={() => { this.fetchActHours(rowData) }} >Fetch</Button> : <CircularProgress style={{ height: '15px', width: '15px' }} />),
                                        customFilterAndSearch: (term, rowData) => {
                                            return (rowData.revised_hours - rowData.actual_hours).includes(term.toLowerCase())
                                        },
                                        filtering: false
                                    },
                                    {
                                        title: 'Hours at completion', field: 'revised_hours',
                                        defaultFilter: this.findFilterValue("revised_hours"),
                                        // render: rowData => <Typography>{this.hourCalc2(rowData, 11)}</Typography>
                                    },
                                    // {
                                    //     title: '% Complete', filed: 'dummy',
                                    //     render: rowData => <Typography></Typography>
                                    // },
                                ]}
                                data={this.state.tabSelect === 0 ? (this.state.data1a ? this.state.data1a : []) : (this.state.data1aFull ? this.state.data1aFull : [])}
                                options={{
                                    paging: false,
                                    padding: "dense",
                                    filtering: true,
                                    doubleHorizontalScroll: true,
                                    pageSize: this.state.data1a ? this.state.data1a.lenght : 0,
                                    pageSizeOptions: [10, 20, 50],
                                    // toolbar: false
                                    exportCsv: (columns, data) => {
                                        exportCsv(columns, data)
                                    },
                                    exportButton: { csv: true },
                                }}
                                onFilterChange={(filter) => { filterData = filter }}
                                actions={(access && access.access && access.access.l4a.l4a_c) ? (
                                    [
                                        rowData => ({
                                            icon: () => rowData.is_favourite ? <StarIcon color='secondary' /> : <StarBorderIcon color='secondary' />,
                                            tooltip: rowData.is_favourite ? "Remove Favourite" : "Add Favourite",
                                            onClick: (event, rowData) => this.addFavourite(rowData),
                                        }),
                                        rowData => ({
                                            icon: 'call_split',
                                            tooltip: 'Resource Allocation',
                                            hidden: !((rowData.tenderbid_data.pdm === user.id && (access && access.access && access.access.l4a.l4a_c)) || (access && access.access && access.access.l4a.l4a_e) || this.checkAccessDecipline(rowData)),
                                            onClick: (event, rowData) => { this.openResourceAllocation2(rowData) },
                                        }),

                                        rowData => ({
                                            icon: 'emoji_people',
                                            tooltip: "DDM / WBS Manager",
                                            hidden: !((rowData.tenderbid_data.pdm === user.id && (access && access.access && access.access.l4a.l4a_c)) || (access && access.access && access.access.l4a.l4a_e) || this.checkAccessDecipline(rowData)),
                                            onClick: (event, rowData) => { this.setState({ openPDM: true, pdmDialData: rowData }) },
                                        }),
                                        rowData => ({
                                            icon: 'credit_card',
                                            tooltip: "Special Rate",
                                            hidden: !(((rowData.tenderbid_data.pdm === user.id)) || (access && access.access && access.access.l4a.l4a_z)),
                                            onClick: (event, rowData) => { this.setState({ openIndiviRate: true, individualRateData: rowData }) },
                                        }),
                                        rowData => ({
                                            icon: 'visibility',
                                            tooltip: "PSR",
                                            hidden: !((rowData.psr && ((access && access.access && access.access.l4a.l4a_u) || (access && access.access && access.access.l4a.l4a_v))) || (access && access.access && access.access.l4a.l4a_ab)),
                                            onClick: (event, rowData) => {
                                                window.open("/report/psr/" + rowData.id + "/11/")
                                            },
                                        }),

                                        rowData => ({
                                            icon: 'watch_later',
                                            tooltip: "Workload Report",
                                            hidden: !(((rowData.tenderbid_data.pdm === user.id || this.allocationPDMAccess(rowData)) && (access && access.access && access.access.l4a.l4a_c)) || (access && access.access && access.access.l4a.l4a_e) || this.checkAccessDecipline(rowData)),
                                            onClick: (event, rowData) => { this.setState({ workloadProjectData: rowData, openWorkloadReport: true }) },
                                        }),

                                        // rowData => ({
                                        //     icon: 'equalizer',
                                        //     tooltip: "EVP",
                                        //     disabled: !((rowData.evp && ((rowData.tenderbid_data.pdm === user.id &&  (access && access.access && access.access.l4a.l4a_x)) || (access && access.access && access.access.l4a.l4a_y))) || (access && access.access && access.access.l4a.l4a_z)),
                                        //     onClick: (event, rowData) => { this.setState({ openEvp: true, toEVPData: rowData }) },
                                        // }),
                                        rowData => ({
                                            icon: () => <Badge style={{ marginRight: '7px' }} max={9} badgeContent={rowData.pending_hrs_request + rowData.pending_task_allocation_request} color="primary">
                                                <PostAddIcon />
                                            </Badge>,
                                            tooltip: "Hours Request",
                                            hidden: !((rowData.tenderbid_data.pdm === user.id || rowData.enable_hours_request) || (access.access && access.access.l4a.l4a_ad)),
                                            onClick: (event, rowData) => { this.setState({ openNoti: true, additionalHoursRequestId: rowData.id, additionalHoursRequest: rowData }) },
                                        }),

                                        rowData => ({
                                            icon: () => <Badge style={{ marginRight: '7px' }} max={9} badgeContent={rowData.pending_date_request} color="primary">
                                                <DateRangeIcon />
                                            </Badge>,
                                            tooltip: "DateChange Request",
                                            hidden: !((rowData.tenderbid_data.pdm === user.id) || (rowData.tenderbid_data.region_hoe.id === user.id)),
                                            onClick: (event, rowData) => { this.setState({ dateRquestDialog: true, requestTimesheetReportData: rowData }) },
                                        }),

                                    ]
                                ) : (null)}
                                detailPanel={rowData => {
                                    return (
                                        <SecuredDeliSch ref={this.refreshCount} updateCount={this.updateCount} value={rowData} onReload={() => this.setState({ updateNeed: true })} projs={this.state.dataAll} roles={this.state.data2} rates={this.state.rates} audit_status={11} tabSelected={this.state.tabSelect} allDataTabs={allDataTabs} />
                                    )
                                }}
                            />
                        ) : (null)}

                        {(this.state.tabSelect === 2 || this.state.tabSelect === 5) ? (
                            <MaterialTable
                                style={{ minWidth: this.state.tableWidth }}
                                title={user.last_name + ", " + user.first_name + "'s Design Complete"}
                                columns={[
                                    {
                                        title: 'Discipline', field: 'tenderbid_data.lead_dicipline', nonExport: true,
                                        headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                                        defaultFilter: this.findFilterValue("tenderbid_data.lead_dicipline"),
                                        lookup: { 'ohl': 'OHL', 'cabling': 'Cabling', 'substation': 'Substation', 'multi_utilities': 'Multi Utilities', 'technical': 'Technical', 'edc': 'EDC' }
                                    },
                                    {
                                        title: 'Project', field: 'tender_name',
                                        defaultFilter: this.findFilterValue("tender_name"),
                                        headerStyle: { minWidth: 300 }, cellStyle: { minWidth: 300 },
                                    }, {
                                        title: 'Created Date', field: 'date_created', type: 'date',
                                        render: rowData => moment(rowData.date_created).format('DD/MM/YYYY')
                                    },
                                    {
                                        title: 'EM', field: 'em_name',
                                        headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                                        // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.pdm_data) ? (rowData.tenderbid_data.pdm_data.last_name + ", " + rowData.tenderbid_data.pdm_data.first_name) : (null),
                                        // defaultFilter: this.findFilterValue("pdm"),
                                        // customFilterAndSearch: (term, rowData) => {
                                        //     return ((rowData.tenderbid_data && rowData.tenderbid_data.pdm_data) ? (rowData.tenderbid_data.pdm_data.last_name + ", " + rowData.tenderbid_data.pdm_data.first_name) : (null)).toLowerCase().includes(term.toLowerCase())
                                        // }
                                    },

                                    {
                                        title: 'Client', field: 'tenderbid_data.client_name',
                                        defaultFilter: this.findFilterValue("tenderbid_data.client_name"),
                                        // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                    },
                                    // {
                                    //     title: 'Region', field: 'tenderbid_data.region_name',
                                    //     defaultFilter: this.findFilterValue("tenderbid_data.region_name"),
                                    //     // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                    // },
                                    {
                                        title: 'HoE', field: 'tenderbid_data.region_hoe.first_name',
                                        headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                                        render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.region_hoe) ? (rowData.tenderbid_data.region_hoe.last_name + ", " + rowData.tenderbid_data.region_hoe.first_name) : (null),
                                        defaultFilter: this.findFilterValue("tenderbid_data.region_hoe.first_name"),
                                        // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.client_name) ? (rowData.tenderbid_data.client_name) : (null)
                                    },
                                    // {
                                    //     title: 'Baseline Finish', field: 'to_date',
                                    //     render: rowData => this.dateParse(rowData.to_date)
                                    // },

                                    {
                                        title: 'Estimated Finish', field: 'to_date',
                                        render: rowData => this.dateParse(rowData.to_date),
                                        defaultFilter: this.findFilterValue("to_date"),
                                        customFilterAndSearch: (term, rowData) => {
                                            return this.dateParse(rowData.to_date).toLowerCase().includes(term.toLowerCase())
                                        }
                                    },

                                    {
                                        title: 'Budget Hours', field: 'budget_hours',
                                        defaultFilter: this.findFilterValue("budget_hours"),
                                        // render: rowData => this.hourCalc(rowData, 10),
                                        // customFilterAndSearch: (term, rowData) => {
                                        //     return rowData.budget_hours.toLowerCase().includes(term.toLowerCase())
                                        // }

                                    },
                                    {
                                        title: 'Hours to date', field: 'actual_hours', nonExport: true,
                                        render: rowData => (rowData.actual_hours || rowData.actual_hours === 0) ? (<Typography>{rowData.actual_hours}</Typography>) : ((!rowData.loading) ? <Button size="small" color="primary" onClick={() => { this.fetchActHours(rowData) }} >Fetch</Button> : <CircularProgress style={{ height: '15px', width: '15px' }} />),
                                        filtering: false
                                    },
                                    // {
                                    //     title: "Hours to come", field: 'dummy',
                                    //     render: rowData => (rowData.actual_hours || rowData.actual_hours === 0) ? (<Typography>{((rowData.revised_hours - rowData.actual_hours) < 0) ? (0) : (rowData.revised_hours - rowData.actual_hours)}</Typography>) : ((!rowData.loading) ? <Button size="small" color="primary" onClick={() => { this.fetchActHours(rowData) }} >Fetch</Button> : <CircularProgress style={{ height: '15px', width: '15px' }} />),
                                    //     filtering: false
                                    // },
                                    {
                                        title: 'Hours at completion', field: 'revised_hours',
                                        defaultFilter: this.findFilterValue("revised_hours"),
                                        // render: rowData => <Typography>{this.hourCalc2(rowData, 10)}</Typography>
                                    },
                                    // {
                                    //     title: '% Complete', filed: 'dummy',
                                    //     render: rowData => <Typography></Typography>
                                    // },


                                ]}
                                data={this.state.tabSelect === 2 ? (this.state.data6a ? this.state.data6a : []) : (this.state.data6aFull ? this.state.data6aFull : [])}
                                options={{
                                    paging: false,
                                    padding: "dense",
                                    search: false,
                                    // padding: this.state.data.length < 7 ? "default" : "dense",
                                    filtering: true,
                                    doubleHorizontalScroll: true,
                                    pageSize: this.state.data6a ? this.state.data6a.length : 0,
                                    pageSizeOptions: [10, 20, 50],
                                    // toolbar: false
                                    exportCsv: (columns, data) => {
                                        exportCsv(columns, data)
                                    },
                                    exportButton: { csv: true },
                                }}
                                actions={[
                                    rowData => ({
                                        icon: () => rowData.is_favourite ? <StarIcon color='secondary' /> : <StarBorderIcon color='secondary' />,
                                        tooltip: rowData.is_favourite ? "Remove Favourite" : "Add Favourite",
                                        onClick: (event, rowData) => this.addFavourite(rowData),
                                    }),
                                    rowData => ({
                                        icon: 'feedback',
                                        tooltip: 'Feedback',
                                        hidden: !(((rowData.tenderbid_data.pdm === user.id) && (access && access.access && access.access.l4a.l4a_f)) || (access && access.access && access.access.l4a.l4a_e) || this.checkAccessDecipline(rowData)),
                                        onClick: (event, rowData) => { this.setState({ openFeedback: true, pdmDialData: rowData }) },
                                    }),
                                    // rowData => ({
                                    //     icon: 'call_split',
                                    //     tooltip: 'Resource Allocation',
                                    //     disabled: !(((rowData.tenderbid_data.pdm === user.id || this.allocationPDMAccess(rowData)) && (access && access.access && access.access.l4a.l4a_c)) || (access && access.access && access.access.l4a.l4a_e) || this.checkAccessDecipline(rowData)),
                                    //     onClick: (event, rowData) => { this.openResourceAllocation(rowData) },
                                    // }),

                                    // rowData => ({
                                    //     icon: 'emoji_people',
                                    //     tooltip: "DDM",
                                    //     disabled: !((rowData.tenderbid_data.pdm === user.id && (access && access.access && access.access.l4a.l4a_c)) || (access && access.access && access.access.l4a.l4a_e) || this.checkAccessDecipline(rowData)),
                                    //     onClick: (event, rowData) => { this.setState({ openPDM: true, pdmDialData: rowData }) },
                                    // }),
                                    // rowData => ({
                                    //     icon: 'credit_card',
                                    //     tooltip: "Special Rate",
                                    //     disabled: !(((rowData.tenderbid_data.pdm === user.id)) || (access && access.access && access.access.l4a.l4a_z)),
                                    //     onClick: (event, rowData) => { this.setState({ openIndiviRate: true, individualRateData: rowData }) },
                                    // }),
                                    rowData => ({
                                        icon: 'visibility',
                                        tooltip: "PSR",
                                        hidden: !((rowData.psr && ((rowData.tenderbid_data.pdm === user.id && (access && access.access && access.access.l4a.l4a_u)) || (access && access.access && access.access.l4a.l4a_v))) || (access && access.access && access.access.l4a.l4a_ab)),
                                        onClick: (event, rowData) => {
                                            window.open("/report/psr/" + rowData.id + "/10/")
                                        },
                                    }),
                                    rowData => ({
                                        icon: 'compare',
                                        tooltip: "MCR",
                                        hidden: !((rowData.psr && ((rowData.tenderbid_data.pdm === user.id && (access && access.access && access.access.l4a.l4a_u)) || (access && access.access && access.access.l4a.l4a_v))) || (access && access.access && access.access.l4a.l4a_ab)),
                                        onClick: (event, rowData) => { window.open("/report/mcr/" + rowData.id) },
                                    }),
                                    rowData => ({
                                        icon: 'equalizer',
                                        tooltip: "EVP",
                                        hidden: !((rowData.evp && ((rowData.tenderbid_data.pdm === user.id && (access && access.access && access.access.l4a.l4a_x)) || (access && access.access && access.access.l4a.l4a_y))) || (access && access.access && access.access.l4a.l4a_z)),
                                        onClick: (event, rowData) => { window.open("/report/evp/" + rowData.id) },
                                    }),
                                    rowData => ({
                                        icon: 'feed',
                                        tooltip: "Timesheet Report",
                                        hidden: !(rowData.tenderbid_data.pdm === user.id || (access && access.access && access.access.l4a.l4a_e)),
                                        onClick: (event, rowData) => {
                                            this.setState({
                                                selectedTender: rowData,
                                                openTimesheetReport: true
                                            })
                                        },
                                    }),



                                    // rowData => ({
                                    //     icon: 'post_add',
                                    //     tooltip: "Hours Request",
                                    //     disabled: !((rowData.tenderbid_data.pdm === user.id) || (access.access && access.access.l4a.l4a_ad)),
                                    //     onClick: (event, rowData) => { this.setState({ openNoti: true, additionalHoursRequestId: rowData.id }) },
                                    // }),
                                    // {
                                    //     icon: () => <Badge badgeContent={this.state.hourRequestPending} {...defaultProps} />,
                                    //     tooltip: "Hours Requests",
                                    //     isFreeAction: true,
                                    //     onClick: () => { this.setState({ openNoti: true, additionalHoursRequestId: null }) },
                                    // },

                                ]}
                                onFilterChange={(filter) => { filterData = filter }}
                                detailPanel={[
                                    {
                                        tooltip: 'Deliverables Schedule',
                                        render: rowData => {
                                            return (
                                                <SecuredDeliSch value={rowData} onReload={() => this.setState({ updateNeed: true })} projs={this.state.dataAll} roles={this.state.data2} rates={this.state.rates} audit_status={13} tabSelected={this.state.tabSelect} allDataTabs={allDataTabs} />
                                            )
                                        }
                                    }]}
                            />
                        ) : (null)}

                        {/* {(this.state.tabSelect === 3) ? (
                            <AdditianalHoursManage value={null} />
                        ) : (null)} */}



                        <Dialog fullScreen open={this.state.openCalc} onClose={handleClose2} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleClose2} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        Resource Allocation for {this.state.allData.tender_name}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <UserTaskMap value={this.state.allData} type={this.state.allocationPDMUsertype} stage1a={this.state.isStage1a} />
                        </Dialog>

                        <Dialog
                            open={this.state.openPDM}
                            fullWidth="true" maxWidth="xl"
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogContent>
                                <TaskManager value={this.state.pdmDialData} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Close
                                </Button>

                            </DialogActions>
                        </Dialog>

                        <Dialog fullWidth={true} open={this.state.openNoti} maxWidth="xl" onClose={handleNotiClose} aria-labelledby="form-dialog-title">
                            <Box display="flex" flexDirection="row-reverse">

                                <Button color='primary' onClick={handleNotiClose}>Close</Button>

                            </Box>
                            <DialogContent>
                                <AdditionalHrsTabs value={this.state.additionalHoursRequestId} count={this.state.additionalHoursRequest} />
                            </DialogContent>


                        </Dialog>

                        <Dialog fullWidth={true} maxWidth="md" open={this.state.openFeedback} onClose={handleFeedbackClose} aria-labelledby="form-dialog-title">
                            {/* <DialogTitle id="customized-dialog-title" onClose={handleFeedbackClose}>
                                    Additional Hours Request
                                </DialogTitle> */}
                            <DialogContent>

                                <TenderFeedbackRequest value={this.state.pdmDialData} />

                            </DialogContent>

                            <DialogActions>
                                <Button onClick={handleFeedbackClose} color="primary">
                                    Close
                                </Button>

                            </DialogActions>
                        </Dialog>

                    </div>
                )}

                {/* <Dialog fullScreen open={this.state.openEvp} onClose={handleClose3} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClose3} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Earned Value Performance for {this.state.toEVPData.tender_name}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <EarnedValue value={this.state.toEVPData} />
                </Dialog> */}

                <Dialog open={this.state.openIndiviRate} onClose={handleClose5}
                    maxWidth={"xl"}
                    fullWidth
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description" >

                    {/* <DialogTitle id="alert-dialog-title">{}</DialogTitle> */}
                    <DialogContent>
                        <SpecialRateTab value={this.state.individualRateData} name={this.state.individualRateData.tender_name} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose5} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* {this.state.customDialogHelper ? <CustomDialogHelper value={customDialogData} onNegative={() => this.setState({ customDialogHelper: false })} onPositive={ } />} */}

                <Dialog
                    open={this.state.openTimesheetReport}
                    maxWidth={"lg"}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Timesheet Report"}</DialogTitle>
                    <DialogContent>
                        <UserTimesheetReport teamUsers={false} userEnabled={false} tender={this.state.selectedTender.id} project_all={allDataTabs.includes(this.state.tabSelect)} />
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleDialogClose} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openWorkloadReport}
                    maxWidth={"xl"}
                    fullWidth
                    onClose={handleWorkloadClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.state.workloadProjectData.tender_name + " - Workload Report"}</DialogTitle>
                    <DialogContent>
                        <ProjectUsersTasks value={this.state.workloadProjectData.id} filterAll={(allDataTabs.includes(this.state.tabSelect))} />
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleWorkloadClose} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.dateRquestDialog}
                    maxWidth={"xl"}
                    fullWidth
                    onClose={handleDateChangeReqClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Box display="flex" flexDirection="row-reverse">
                        <Button color='primary' onClick={handleDateChangeReqClose}>Close</Button>
                    </Box>
                    <DialogContent>
                        <DateChangeReqManage
                            value={this.state.requestTimesheetReportData}
                            refreshData={this.refreshData}
                        />
                    </DialogContent>
                </Dialog>

                <Dialog fullScreen open={this.state.openSubTender} aria-labelledby="customized-dialog-title" TransitionComponent={Transition}>
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleSubTenderClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" >
                                {this.state.subTenderData ? this.state.subTenderData.tender_name : null}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {/* <DialogContent> */}
                    <MainTenderSubTasks tender_id={this.state.subTenderData ? this.state.subTenderData.id : null} />
                    {/* </DialogContent> */}
                </Dialog>
            </div >

        )
    }
}

function addZeroes(num) {
    const dec = num.toString().split('.')[1]
    const len = dec ? 1 : null
    if (len) {
        return parseFloat(Number(num).toFixed(len))
    } else {
        return parseFloat(Number(num))
    }
}

function timeToDecimal(t) {
    var arr = t.split(':');
    var dec = parseInt((arr[1] / 6) * 10, 10);

    return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
}

export default withStyles(useStyles)(withSnackbar(SecuredWorks));