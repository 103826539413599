import { Avatar, Button, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, Rating, Toolbar, Typography } from '@mui/material'
import React, { Component } from 'react'
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading'
import { deleteCookie, getCookie, setCookie } from '../Helper/CookieHelper'
import ReportCards from './ReportCards/ReportCards'
import Tablayout from './Tablayout/Tablayout'
import { Alert } from '@material-ui/lab';
import { getPrimaryColor, sortByProperty } from '../Helper/CommonThings'
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Calender from '../Teams/Calender'
import CalenderNonEdit from '../Teams/CalenderNonEdit'
import Service from '.././Networking/networkutils'
import { errorMessage } from '../Helper/ErrorMessage'
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import TimeSheetDialog from '../TimeSheet/TimeSheetDialog'
import LMSLeaveSubmit from '../LMS/LMSLeaveSubmit'
import TeamHoursRequestTabs from '../HoursRequest/TeamHoursRequestTabs'
import UserHoursRequestManage from '../HoursRequest/UserHoursRequestManage'
import { AppBar, Box } from '@material-ui/core'
import { withSnackbar } from 'notistack';
import MyTasks from './Tablayout/MyTasks/MyTasks';
import FeedbackView from '../Feedback/FeedbackView'
import moment from 'moment'
import { BootstrapDialogTitleV4 } from '../Helper/DialogTitleM5'
import { getredcolor } from './ColorTheme/ColorTheme'
import { CoffeeLoading } from 'react-loadingg'
// import WorkPlanDialog from '../Teams/WorkPlans/WorkPlanDialog'

const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));
const token = getCookie("bb_ts_token");

var countStarttime = 0;
var countStarttimeMax = 10800;

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

var pendingRequestData = null

var currentFeedbackCategory = null

class MainDashBoard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            cards: 0,
            isLoading: true,
            isLoading1: true,
            isLogin: false,
            openCal: false,
            calData: {},
            calTitle: '',
            userPermission: [],
            teamTaskData: [],
            myTaskData: [],
            openWho: false,
            memdata: [],
            editableCal: true,
            useraccess: [],
            tstitle: "Timesheet",
            lmstitle: "Submit Leave",
            openTS: false,
            lmsUid: null,
            openLMSSubmit: false,
            openHourRequest: false,
            selectedTeam: null,
            openloader: false,
            taskdata: [],
            show_tab: null,
            allForFeedbackCategory: [],
            openFeedback: false,
            openFeedbackView: false,
            averageRating: null,
            feedbackData: {},
            ratingFeedbackData: {},
            ratingFeedbacks: [],
            disableFeedbackLater: false,
            timesheetAlert: false,
            timesheetOption_cur_user: 0,
            timesheetOption_ano_user: 0,
            coffeeLoading: false
        }
        this.progress = React.createRef();
        this.cardChild = React.createRef();
        this.feedbackRef = React.createRef();
        this.handleView = this.handleView.bind(this)
        this.ratingClick = this.ratingClick.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.fetchMyTaskData = this.fetchMyTaskData.bind(this)
        this.launchAuto = this.launchAuto.bind(this)
        this.openHrsRequest = this.openHrsRequest.bind(this)
        this.refreshData = this.refreshData.bind(this)
        this.refreshSubTender = this.refreshSubTender.bind(this)
        this.openLoader = this.openLoader.bind(this)
        this.getRequestCountsImdt = this.getRequestCountsImdt.bind(this)
    }

    componentDidMount() {
        if (getCookie("bb_ts_token")) {
            // clearInterval(this.interval)
            this.fetchMyTaskData("start", 0);
            this.fetchOps();
            this.showTab('start')
            this.fetchFeedbackOptions()
        } else {
            deleteCookie("bb_ts_token");
            deleteCookie("bb_ts_user");
            deleteCookie("bb_ts_access");
            deleteCookie("bb_ts_user_settings");
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    showTab(data) {
        // debugger
        if (data === 'start') {
            if (localStorage.getItem('bookmarked_tab') === 'my_tasks') {
                this.setState({ show_tab: 'my_tasks' })
            } else if ((localStorage.getItem('bookmarked_tab') === 'team_tasks') && (this.state.myTaskData.lead_in_teams !== 0)) {
                this.setState({ show_tab: 'team_tasks' })
            } else if ((localStorage.getItem('bookmarked_tab') === 'project_tasks') && (access.access && access.access.l4a && access.access.l4a.l4a_a)) {
                this.setState({ show_tab: 'project_tasks' })
            } else if ((localStorage.getItem('bookmarked_tab') === 'hoe_gate6') && (this.state.myTaskData.is_hoe)) {
                this.setState({ show_tab: 'hoe_gate6' })
            } else if ((localStorage.getItem('bookmarked_tab') === 'kpi') && (access.access && access.access.l4a && access.access.l4a.l4a_a)) {
                this.setState({ show_tab: 'kpi' })
            } else {
                this.setState({ show_tab: 'my_tasks' })
            }
        }
        else if (data === 'reload') {
            this.setState({ show_tab: 'my_tasks' })
        }
    }

    getRequestCountsImdt(my_task_data) {
        if (!my_task_data) {
            my_task_data = this.state.myTaskData
        }
        Service.get(`/bb/api/tender/project/pending_request/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                pendingRequestData = res.data
                countStarttime = 10
                if (this.cardChild && this.cardChild.current) {
                    this.cardChild.current.getBadgeCount(res.data)
                }

                if ((this.progress && this.progress.current) && (my_task_data.lead_in_teams !== 0)) {
                    this.progress.current.getBadgeCount(res.data)
                }
                if (countStarttime < countStarttimeMax && (my_task_data.lead_in_teams !== 0)) {
                    if (res.data.total_hours_request > 0 || res.data.total_timesheet_count > 0 || res.data.total_tender_count > 0) {
                        countStarttimeMax = 10800
                    } else {
                        countStarttimeMax = 0
                    }
                    if (countStarttime < countStarttimeMax) {
                        if (this.interval) {
                            clearInterval(this.interval)
                        }
                        this.getRequestCounts()
                    }
                }
                if (this.progress && this.progress.current) {
                    this.progress.current.setRating(this.state.averageRating);
                }
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    getRequestCounts() {
        this.interval = setInterval(() => {
            Service.get(`/bb/api/tender/project/pending_request/`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    countStarttime += 10
                    if (pendingRequestData.pending_tender_request !== res.data.pending_tender_request ||
                        pendingRequestData.pending_timesheet_request !== res.data.pending_timesheet_request ||
                        pendingRequestData.pending_hours_request !== res.data.pending_hours_request ||
                        pendingRequestData.pending_subtask !== res.data.pending_subtask) {

                        if (this.cardChild && this.cardChild.current) {
                            this.cardChild.current.getBadgeCount(res.data)
                        }
                        if (this.progress && this.progress.current) {
                            this.progress.current.getBadgeCount(res.data)
                        }
                    }
                    pendingRequestData = res.data
                    if (countStarttime > countStarttimeMax) {
                        clearInterval(this.interval)
                    }
                })
                .catch(error => {
                    clearInterval(this.interval)
                    console.log(error)
                    // errorMessage(this.props, error)
                });
        }, 10000);
    }

    fetchOps() {
        user.uid = user.id;
        user.name = user.first_name + " " + user.last_name;
        localStorage.removeItem("staff_to_see")
        var url = "/bb/api/team/access/?uid=" + user.id;
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res1 => {
                this.setState({
                    useraccess: res1.data
                })

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    handleView(data) {
        if (this.cardChild && this.cardChild.current) {
            this.cardChild.current.changeView(data);
        }
    }

    handleClick(data) {
        if (data) {
            // this.setState({ coffeeLoading: true }, () => {
            // this.fetchTimesheetOptioForAnotherUSer(data)
            this.handleLaunch(data, 'cal')
            // })
        }
    }

    fetchMyTaskData(data, setinterval) {
        this.fetchTimesheetOption()
        if (data === 'reload') {
            this.setState({ isLoading1: true })
        }
        Service.get(`/bb/api/tender/project/user_based_tasks/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                this.showTimesheetAlert(res.data)
                if (setinterval === 0) {
                    this.getRequestCountsImdt(res.data);
                }
                this.setState({
                    myTaskData: res.data,
                    isLoading: false,
                    isLoading1: false
                })
                if (data === 'continue' && this.progress && this.progress.current) {
                    this.progress.current.fetchOps(res.data);
                }
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    showTimesheetAlert(data) {
        if (moment(getCookie('timesheet_alert')) <= moment("20-05-2023")) {
            deleteCookie('timesheet_alert')
        }
        if (data.last_working_day === moment().format("YYYY-MM-DD") && !getCookie('timesheet_alert')) {
            if (data.total_hours > data.planned && data.total_hours > data.approved) {
                if (!getCookie('timesheet_alert') || getCookie('timesheet_alert') !== data.last_working_day) {
                    setCookie('timesheet_alert', moment().format("YYYY-MM-DD"), { secure: true, 'max-age': 43200, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                    this.setState({ timesheetAlert: true })
                }
            }
        }
    }

    getUserAccess(uid) {
        Service.get(`/bb/api/access/?uid=` + uid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                this.setState({
                    userPermission: res.data,
                });

            })
            .catch(error => {
                this.setState({ isLoading: false })
                errorMessage(this.props, error)
            });
    }

    handleLaunch(dataIn, type) {
        if (type === 'cal') {
            if (dataIn.team_role !== 0) {
                this.setState({ calData: dataIn })
                this.requestMem(dataIn.id)
            } else {
                user.uid = user.id; user.name = user.first_name + " " + user.last_name; localStorage.setItem("staff_to_see", JSON.stringify(user));
                this.setState({ timesheetOption_ano_user: this.state.timesheetOption_cur_user }, () => {
                    this.setState({ calData: dataIn, openCal: true, editableCal: true, calTitle: '', userPermission: [access] })
                })
            }
        }
        //  else if (type === 'ts') {

        //     if (dataIn.team_role!==0) {
        //         this.setState({ calData: dataIn })
        //         this.requestMem1(dataIn.id)
        //     }
        // }
    }

    fetchTimesheetOptioForAnotherUSer(userData) {
        // console.log(userData)
        this.setState({ coffeeLoading: true })
        Service.get(`/bb/api/user/settings/get_users_settings/?user_id=${userData.uid}&key=${'calender_view_option'}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                localStorage.setItem("staff_to_see", JSON.stringify(userData));
                this.getUserAccess(userData.uid)
                this.setState({
                    coffeeLoading: false,
                    timesheetOption_ano_user: res.data.value,
                }, () => {
                    this.setState({ openWho: false, openCal: true, editableCal: true, calTitle: ' (' + userData.name + ')', userPermission: [] });
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
                this.setState({ coffeeLoading: false })
                this.props.enqueueSnackbar("Error in fetching Timesheet option", { variant: 'error' });
            });

    }

    fetchTimesheetOption() {
        if (!getCookie('bb_ts_user_settings')) {
            Service.get(`/bb/api/user/settings/settings_status/`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    setCookie("bb_ts_user_settings", JSON.stringify(res.data))
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        if (element.key === 'calender_view_option') {
                            this.setState({
                                timesheetOption_cur_user: element.value == null ? element.default_value : element.value,
                            })
                            break;
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                    this.props.enqueueSnackbar("Error in fetching User Settings", { variant: 'error' });
                });
        } else {
            let data = JSON.parse(getCookie('bb_ts_user_settings'))
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                if (element.key === 'calender_view_option') {
                    this.setState({
                        timesheetOption_cur_user: element.value == null ? element.default_value : element.value,
                    })
                    break;
                }
            }
        }
    }

    requestMem(id) {
        this.setState({ openloader: true })
        Service.get("/bb/api/team/access/?gid=" + id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var data123 = []
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].team_role !== 3) {
                        res.data[i].name = res.data[i].uname_last + ", " + res.data[i].uname_first;
                        data123.push(res.data[i])
                    }
                }
                this.setState({ openWho: true, memdata: data123, isLoading: false, openloader: false })
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    requestMem22(uid, gid) {
        Service.get("/bb/api/team/access/?gid=" + gid + "&uid=" + uid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                var element = res.data[0]
                localStorage.setItem("staff_to_see", JSON.stringify(element)); this.setState({ openWho: false, openCal: true, editableCal: true, calData: element.team_data, calTitle: '(' + element.uname_last + ", " + element.uname_first + ')' });

                // this.setState({ memdata: res.data, isLoading: false, isLoadingDum: false });
                // for (let i = 0; i < res.data.length; i++) {
                //     const element = res.data[i];
                //     if (uid === element.uid) {

                //         localStorage.setItem("staff_to_see", JSON.stringify(element)); this.setState({ openWho: false, openCal: true, editableCal: true, calData: { name: element.gname }, calTitle: '(' + element.uname_last + ", " + element.uname_first + ')' });
                //         break;
                //     }
                // }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    launchAuto(uid, gid) {
        this.setState({ openCal: false })
        this.requestMem22(uid, gid);
    }

    handleLMSSubmitCalender(dataIn) {
        var json21 = JSON.parse(localStorage.getItem("staff_to_see"))
        this.setState({ openLMSSubmit: true, lmsUid: json21.uid, lmstitle: "Submit Leave " + this.state.calTitle, })
    }

    handleTSinCalender(dataIn) {
        // var json21 = JSON.parse(localStorage.getItem("staff_to_see"))
        this.setState({ openTS: true, tstitle: "Timesheet " + this.state.calTitle, })
    }

    openHrsRequest(data) {
        this.setState({ openHourRequest: true, selectedTeam: data })
    }

    refreshData() {
        this.fetchMyTaskData('reload', 1)
        this.showTab('reload')
    }

    refreshSubTender() {
        this.fetchMyTaskData('continue', 1)
    }

    ratingClick() {
        this.setState({ openFeedbackView: true }, () => {
            setTimeout(() => {
                this.messagesEnd.scrollIntoView({ behavior: "smooth" })
            }, 200);
        })
    }

    openLoader(data) {
        if (data === 'open') {
            this.setState({ openloader: true })
        } else {
            this.setState({ openloader: false })
        }
    }

    fetchFeedbackOptions() {
        Service.get("/bb/api/user/feedback/get_feedback_info/", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data.activate_collection) {
                    currentFeedbackCategory = res.data.activate_collection.feedback_code
                    if (res.data.show_rating) {
                        if (res.data.activate_collection.id === res.data.show_rating.id) {
                            this.fetchFeedbackOrGet(res.data.activate_collection.feedback_code, "both")
                            this.setState({
                                feedbackData: res.data.activate_collection,
                                ratingFeedbackData: res.data.activate_collection
                            })
                        } else {
                            this.setState({
                                feedbackData: res.data.activate_collection,
                                ratingFeedbackData: res.data.show_rating
                            })
                            this.fetchFeedbackOrGet(res.data.activate_collection.feedback_code, "feedback")
                            this.fetchFeedbackOrGet(res.data.show_rating.feedback_code, "rating")
                        }
                    } else {
                        this.setState({
                            feedbackData: res.data.activate_collection,
                        })
                        this.fetchFeedbackOrGet(res.data.activate_collection.feedback_code, "feedback")
                    }
                } else {
                    if (res.data.show_rating) {
                        this.setState({
                            ratingFeedbackData: res.data.show_rating
                        })
                        this.fetchFeedbackOrGet(res.data.show_rating.feedback_code, "rating")
                    }
                }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchFeedbackOrGet(category, operation_type) {
        Service.get2("/bb/api/feedback/?category=" + category, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res)

                var available = false
                var addOfAllRating = 0

                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    addOfAllRating += element.rating
                    if (user.id === element.uid) {
                        localStorage.setItem(category, "true")
                        available = true
                    }
                }

                const averageRating = addOfAllRating / res.data.length

                const date_joined = moment(user.date_joined)

                const applicable_for_feedback = date_joined < moment().subtract(10, 'days')

                var openFeedback = (["feedback", "both"].includes(operation_type) && applicable_for_feedback) ? !available : this.state.openFeedback

                const delayCheck = openFeedback && parseInt(localStorage.getItem("feedback_dalay")) === parseInt(moment().utc().format("DDMMYYYY"))

                const dateCreatedCheck = openFeedback && moment(this.state.feedbackData.date_created) >= moment().subtract(15, 'days')
                // alert(delayCheck && dateCreatedCheck)
                if (delayCheck && dateCreatedCheck) {
                    openFeedback = false
                }

                this.setState({
                    allForFeedbackCategory: ["feedback", "both"].includes(operation_type) ? res.data : this.state.allForFeedbackCategory,
                    openFeedback: openFeedback,
                    averageRating: ["rating", "both"].includes(operation_type) ? averageRating : this.state.averageRating,
                    ratingFeedbacks: ["rating", "both"].includes(operation_type) ? res.data : this.state.ratingFeedbacks,
                    disableFeedbackLater: !dateCreatedCheck,
                }, () => {
                    if (this.progress && this.progress.current) {
                        this.progress.current.setRating(this.state.averageRating);
                    }
                    // this.messagesEnd.scrollIntoView({ behavior: "smooth" });
                    // myDiv.scrollTop = myDiv.scrollHeight;
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    render() {
        const { classes } = this.props;

        const handleCalClose = () => {
            this.setState({
                openCal: false,
                timesheetOption_ano_user: 0
            }, () => {
                this.fetchMyTaskData('continue', 1)
            })

        };

        const handleWhoClose = () => {
            this.setState({
                openWho: false,
            })
        };

        const handleTSClose = () => {
            this.setState({
                openTS: false,
            })
        };

        const handletimesheetAlertClose = () => {
            this.setState({
                timesheetAlert: false,
            })
        };


        const handleLMSSubmitClose = () => {
            this.setState({
                openLMSSubmit: false,
            })
        };

        const handleHourRequestClose = () => {
            this.cardChild.current.getPendingRequest(this.state.myTaskData.teams)
            this.getRequestCountsImdt(null)
            this.setState({
                openHourRequest: false,
            })
        };

        const handleFeedbackClose = () => {
            localStorage.setItem(currentFeedbackCategory, "true")
            this.fetchFeedbackOrGet(this.state.ratingFeedbackData.feedback_code, "rating")
            this.setState({
                openFeedback: false,

            })
        };

        const handleFeedbackLaterClose = () => {
            localStorage.setItem("feedback_dalay", moment().utc().format("DDMMYYYY"))
            this.setState({
                openFeedback: false,
            })
        };

        const handleFeedbackViewClose = () => {
            this.setState({
                openFeedbackView: false,

            })
        };

        return (

            <div>
                {
                    this.state.isLoading ?
                        <Grid container direction="row" justifyContent="center" alignItems="center" >
                            <LoopCircleLoading color={getPrimaryColor()} />
                        </Grid> :
                        <>
                            <Grid container direction="row" justifyContent="center" alignItems="center" >
                                <ReportCards timesheetOption={this.state.timesheetOption_cur_user} ref={this.cardChild} refreshChart={handleCalClose} mytasks={this.state.cards} myTaskTeamData={this.state.myTaskData} getUpdatedData={this.fetchMyTaskData} openHrsRequest={this.openHrsRequest} requestCountRefresh={this.getRequestCountsImdt} />
                            </Grid>
                            {
                                this.state.isLoading1 ?
                                    <Grid container direction="row" justifyContent="center" alignItems="center" >
                                        <LoopCircleLoading color={getPrimaryColor()} />
                                    </Grid> :

                                    <Grid container direction="row" justifyContent="left" alignItems="center" style={{ marginTop: '10px' }}>
                                        {!this.state.myTaskData.timesheet_submitted_status ?
                                            <Box margin={'7px'} style={{ width: '100%' }}>
                                                <Alert severity="warning">{this.state.myTaskData.prev_week_submitted_timesheet_hours === 0 ? 'You have pending timesheets to be submitted. Please goto MyCalendar to submit your timesheet.' : "You have pending timesheets to be submitted. " + (this.state.myTaskData.total_hours - this.state.myTaskData.prev_week_submitted_timesheet_hours) + " hours of your workload is balance in your last week timesheet. Please goto MyCalendar to submit your timesheet."}</Alert>
                                            </Box>
                                            : null}
                                        {(true || this.state.myTaskData.lead_in_teams !== 0 || (access.access && access.access.l4a && access.access.l4a.l4a_a)) ?
                                            <Tablayout ref={this.progress} openLoader={this.openLoader} launchAuto={this.launchAuto} handleView={this.handleView} myTaskData={this.state.myTaskData} handleClick={this.handleClick} refreshData={this.refreshData} refreshSubTender={this.refreshSubTender} ratingClick={this.ratingClick} showTab={this.state.show_tab} requestCountRefresh={this.getRequestCountsImdt} />
                                            :
                                            <Container maxWidth={false} disableGutters>
                                                <MyTasks data={this.state.myTaskData} show_profile={true} handleClick={this.handleClick} refreshData={this.refreshData} taskdata={this.state.taskdata ? this.state.taskdata : null} ref={this.progress} />
                                            </Container >
                                        }
                                    </Grid>
                            }
                        </>
                }

                <Dialog fullScreen open={this.state.openCal} onClose={handleCalClose} TransitionComponent={Transition}>
                    <AppBar position='relative' >
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleCalClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {this.state.calData.name + this.state.calTitle}
                            </Typography>
                            {(this.state.userPermission.length && this.state.userPermission[0].access && this.state.userPermission[0].access.l18a && this.state.userPermission[0].access.l18a.l18a_a) ? (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    startIcon={<ListAltIcon />}
                                    style={{ marginRight: '5px' }}
                                    onClick={() => { this.handleLMSSubmitCalender(this.state.calData) }}
                                >
                                    Submit Leave
                                </Button>
                            ) : (null)}
                            {(this.state.editableCal && access && access.access && access.access.l1a && access.access.l1a.l1a_c) ? (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    startIcon={<ListAltIcon />}
                                    onClick={() => { this.handleTSinCalender(this.state.calData) }}
                                >
                                    Timesheet
                                </Button>
                            ) : (null)}
                        </Toolbar>
                    </AppBar>
                    {(this.state.editableCal) ? (
                        (!this.state.openLMSSubmit) && (!this.state.openTS) ? <Calender value={this.state.calData} useraccess={this.state.useraccess} timesheetOption={this.state.timesheetOption_ano_user} /> : null

                    ) : (
                        <CalenderNonEdit value={this.state.calData} launchAuto={this.launchAuto} />
                    )}
                </Dialog>

                <Dialog onClose={handleWhoClose} aria-labelledby="simple-dialog-title" open={this.state.openWho}>
                    <DialogTitle id="simple-dialog-title">Select Resource(s)</DialogTitle>

                    <List>
                        {['All Members Data (View only)'].map((text, index) => (
                            <ListItem button key={index} onClick={() => { localStorage.setItem("staff_to_see", "0"); this.setState({ openWho: false, openCal: true, editableCal: false, userPermission: [], calTitle: '' }) }} >
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        <GroupIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={text} />
                            </ListItem>
                        ))}

                        {this.state.memdata.sort(sortByProperty("name")).map((text, index) => (
                            <ListItem button key={index} onClick={() => { this.fetchTimesheetOptioForAnotherUSer(text) }} >
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        <PersonIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={text.name} />
                            </ListItem>
                        ))}
                    </List>

                </Dialog>

                <Dialog fullScreen open={this.state.openTS} onClose={handleTSClose} TransitionComponent={Transition}>
                    <AppBar position='relative'>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleTSClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {this.state.tstitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <TimeSheetDialog timesheetOption={this.state.timesheetOption_ano_user} value={this.state.calData} useraccess={this.state.useraccess} access={this.state.userPermission[0]} />
                </Dialog>

                <Dialog fullScreen open={this.state.openLMSSubmit} onClose={handleLMSSubmitClose} TransitionComponent={Transition}>
                    <AppBar position='relative'>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleLMSSubmitClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {this.state.lmstitle}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <LMSLeaveSubmit uid={this.state.lmsUid} />
                </Dialog>

                <Dialog onClose={handleHourRequestClose} aria-labelledby="customized-dialog-title" maxWidth="xl" open={this.state.openHourRequest}>
                    <DialogContent dividers>
                        {(this.state.selectedTeam && this.state.selectedTeam.lead) ? (
                            <TeamHoursRequestTabs value={this.state.selectedTeam} onCloseCommand={handleHourRequestClose} />
                        ) : (
                            <div>
                                <Box display="flex" flexDirection="row-reverse">
                                    <Button color='primary' onClick={handleHourRequestClose}>Close</Button>
                                </Box>
                                <UserHoursRequestManage value={this.state.selectedTeam} onCloseCommand={handleHourRequestClose} />
                            </div>
                        )}
                    </DialogContent>
                </Dialog>

                <Dialog maxWidth="sm" open={this.state.openloader} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <Box m={5} p={2}>
                            <LoopCircleLoading />
                        </Box>
                    </DialogContent>
                </Dialog>

                <Dialog open={this.state.openFeedback} fullWidth aria-labelledby="form-dialog-title">
                    <DialogTitle style={{ paddingTop: '25px' }} id="form-dialog-title">{this.state.feedbackData.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ paddingTop: '5px' }} id="alert-dialog-slide-description">
                            {this.state.feedbackData.decs}
                        </DialogContentText>
                        <FeedbackView value={this.state.feedbackData.feedback_code} ref={this.feedbackRef} handleClose={handleFeedbackClose} />
                    </DialogContent>
                    <DialogActions>
                        {/* <Button onClick={handle1YarCelebClose} color="primary">
                            Cancel
                        </Button> */}
                        <Button onClick={handleFeedbackLaterClose} disabled={this.state.disableFeedbackLater} color="primary">
                            Later
                        </Button>
                        <Button onClick={e => {
                            // handle1YarCelebClose()
                            this.feedbackRef.current.submitFeedback()
                        }} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openFeedbackView} fullWidth aria-labelledby="form-dialog-title" onClose={handleFeedbackViewClose}>
                    <DialogTitle style={{ paddingTop: '25px' }} id="form-dialog-title">{this.state.ratingFeedbackData.title}</DialogTitle>
                    <DialogContent>
                        <div

                            style={{
                                height: `80vh`,
                                overflowY: "scroll",
                                minWidth: "50%",
                            }}
                        >
                            {this.state.ratingFeedbacks.map((value, index) =>
                                <Box p={1} key={index}>
                                    <Card className={classes.root}>
                                        <CardHeader
                                            avatar={
                                                <Avatar aria-label="recipe" className={classes.avatar}>
                                                    {value.uid_data.first_name[0]}
                                                </Avatar>
                                            }
                                            title={value.uid_data.last_name + ", " + value.uid_data.first_name}
                                            subheader={moment(value.date_created).fromNow()}
                                        />
                                        <CardContent>
                                            <Rating name="read-only" precision={0.5} value={value.rating} readOnly />
                                        </CardContent>
                                    </Card>
                                </Box>

                            )}
                            <div ref={(el) => { this.messagesEnd = el; }} />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleFeedbackViewClose} color="primary">
                            Cancel
                        </Button>

                    </DialogActions>
                </Dialog>

                <Dialog fullWidth={true} onClose={handletimesheetAlertClose} aria-labelledby="customized-dialog-title" maxWidth="sm" open={this.state.timesheetAlert}>
                    <BootstrapDialogTitleV4 id="timesheet_alert" onClose={handletimesheetAlertClose}><div className="blink" style={{ color: getredcolor() }}>Timesheet Alert</div></BootstrapDialogTitleV4>
                    <DialogContent >
                        <Box display={'flex'} flexDirection={'column'}>
                            <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} fontWeight={500}>
                                <Typography variant='h6'>
                                    Hi {user.first_name}!
                                </Typography>
                            </Box>
                            <Box mt={1}>
                                Hope this is your last working day of the current week. This is a gentle reminder to book your timesheets for this week.
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() =>
                            this.setState({ timesheetAlert: false }, () => {
                                if (this.cardChild && this.cardChild.current) {
                                    this.cardChild.current.openMyCal()
                                }
                            })}>
                            Goto MyCalendar
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog maxWidth="sm" open={this.state.coffeeLoading} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <Box m={5} p={2}>
                            <CoffeeLoading />
                        </Box>
                    </DialogContent>
                </Dialog>
                {/* <WorkPlanDialog autoCheck={true} date={moment().format()} /> */}
            </div>

        )
    }
}

export default withStyles(useStyles)(withSnackbar(MainDashBoard))