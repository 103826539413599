import React, { Component } from 'react'
import { Box, styled } from '@mui/system';
import { Tabs as TabsUnstyled } from '@mui/base/Tabs';
import { TabsList as TabsListUnstyled } from '@mui/base/TabsList';
import { TabPanel as TabPanelUnstyled } from '@mui/base/TabPanel';
import { Tab as TabUnstyled, tabClasses } from '@mui/base/Tab';
import MyTasks from './MyTasks/MyTasks';
import TeamTasks from './TeamTasks/TeamTasks';
import { Badge, Container, IconButton, Tooltip, Typography } from '@mui/material';
// import { Card, CardActionArea, CardContent } from '@mui/material';
import RefreshIcon from '@material-ui/icons/Refresh';
import ProjectTasks from './ProjectTasks/ProjectTasks';
import { getCookie } from '../../Helper/CookieHelper';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { withSnackbar } from 'notistack';
import { getDarkMode, getPrimaryColor, getSecondaryColor } from '../../Helper/CommonThings';
import KpiDashboard from '../../Kpi/KpiDashboard';
import Tutorials from '../../Tutorials/Tutorials';
import BroadCast from '../../BroadCast/BroadCast';
import { Dialog, Toolbar, } from '@mui/material'
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
// import SportsVolleyballIcon from '@material-ui/icons/SportsVolleyball';
// import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import { withStyles } from '@material-ui/core';
import HoeGate6 from '../../HoeGate6/HoeGate6';
import { screenSizeCheck } from '../ScreensizeCheck/ScreensizeCheck';
import { AppBar } from '@material-ui/core';

// import { screenSizeCheck } from '../ScreensizeCheck/ScreensizeCheck';

const access = JSON.parse(getCookie("bb_ts_access"));
// const user = JSON.parse(getCookie("bb_ts_user"));

const Tab = styled(TabUnstyled)`
  font-family: roboto;
  color: ${getPrimaryColor()};
  cursor: pointer;
  font-size: ${screenSizeCheck() ? '1rem' : '1.2rem'};
  font-weight: bold;
  background-color: transparent;
  padding: 12px 50px;
  margin: 2px 2px 0px 0px ;
  border: none;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  justify-content: center;
  outline:2px solid #a3c2c2;
  box-shadow:1px 1px 1px 1px #a3c2c2;
  &:hover {
    background-color: ${getSecondaryColor()};
  }

  &:focus {
    color: white;
    border-radius: 20px 20px 0px 0px
    // outline: 2px solid ${getPrimaryColor()};
    // outline-offset: 2px;
  }

  &.${tabClasses.selected} {
    background-color: ${getPrimaryColor()};
    color: white;
  }

`;

const TabPanel = styled(TabPanelUnstyled)`
  width: '100%';
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: left;
  justify-content: left;
  align-content: space-between;
`;

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -14,
    top: -8,
  },
}));

const sideOptionNoAccessList = ['tutorials', 'hoe_gate6']

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
});

class Tablayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.showTab,
      bookmark_data: localStorage.getItem('bookmarked_tab'),
      icon_color: 'primary',
      badgeCount: 0,
      badgeCount2: 0,
      averageRating: null,
      openBroadCast: false,
      form1Dialog: false,
      form2Dialog: false,
      form3Dialog: false,
    }
    this.progress = React.createRef();
    this.cardChild = React.createRef();
    this.handleClick = this.handleClick.bind(this)
    this.launchAuto = this.launchAuto.bind(this)
    this.refreshData = this.refreshData.bind(this)
    this.refreshSubTender = this.refreshSubTender.bind(this)
    this.openLoader = this.openLoader.bind(this)
  }

  componentDidMount() {
    if (getDarkMode() === 'dark') {
      this.setState({ icon_color: 'secondary' })
    }
  }

  handleClick(data) {
    if (data) {
      this.props.handleClick(data)
    }
  }

  showTab() {
    if (localStorage.getItem('bookmarked_tab') === 'my_tasks') {
      return 'my_tasks'
    } else if ((localStorage.getItem('bookmarked_tab') === 'team_tasks') && (this.props.myTaskData.lead_in_teams !== 0)) {
      return 'team_tasks'
    } else if ((localStorage.getItem('bookmarked_tab') === 'project_tasks') && (access.access && access.access.l4a && access.access.l4a.l4a_a)) {
      return 'project_tasks'
    } else if ((localStorage.getItem('bookmarked_tab') === 'hoe_gate6') && (this.props.myTaskData.is_hoe)) {
      return 'hoe_gate6'
    } else if ((localStorage.getItem('bookmarked_tab') === 'kpi') && (access.access && access.access.l4a && access.access.l4a.l4a_a)) {
      return 'kpi'
    } else {
      return 'my_tasks'
    }
  }

  launchAuto(uid, gid) {
    this.props.launchAuto(uid, gid)
  }

  refreshData() {
    this.props.refreshData()
  }

  refreshSubTender() {
    this.props.refreshSubTender()
  }

  setRating(rating) {
    this.setState({
      averageRating: rating
    })
  }

  fetchOps(data) {
    if (this.progress && this.progress.current) {
      this.progress.current.fetchOps(data);
    }
  }

  openLoader(data) {
    this.props.openLoader(data)
  }

  reload() {
    this.props.requestCountRefresh(null)
    if (this.state.value === 'my_tasks') {
      // this.progress.current.reloadData()
      this.props.refreshData()
      // this.setState({ value: '' }, () => {
      //   this.setState({ value: 'my_tasks' })
      // })
    } else if (this.state.value === 'team_tasks') {
      this.progress.current.refreshData()
    } else if (this.state.value === 'project_tasks') {
      this.progress.current.preFetchData()
    } else if (this.state.value === 'kpi') {
      this.progress.current.fetchData()
    } else if (this.state.value === 'hoe_gate6') {
      this.progress.current.reload()
    }
  }

  bookMarkTab(value) {
    if (value === 'my_tasks') {
      if (localStorage.getItem('bookmarked_tab') === 'my_tasks') {
        localStorage.removeItem("bookmarked_tab")
      } else {
        localStorage.setItem('bookmarked_tab', 'my_tasks')
      }
    } else if (value === 'team_tasks') {
      if (localStorage.getItem('bookmarked_tab') === 'team_tasks') {
        localStorage.removeItem("bookmarked_tab")
      } else {
        localStorage.setItem('bookmarked_tab', 'team_tasks')
      }
    } else if (value === 'project_tasks') {
      if (localStorage.getItem('bookmarked_tab') === 'project_tasks') {
        localStorage.removeItem("bookmarked_tab")
      } else {
        localStorage.setItem('bookmarked_tab', 'project_tasks')
      }
    } else if (value === 'kpi') {
      if (localStorage.getItem('bookmarked_tab') === 'kpi') {
        localStorage.removeItem("bookmarked_tab")
      } else {
        localStorage.setItem('bookmarked_tab', 'kpi')
      }
    } else if (value === 'hoe_gate6') {
      if (localStorage.getItem('bookmarked_tab') === 'hoe_gate6') {
        localStorage.removeItem("bookmarked_tab")
      } else {
        localStorage.setItem('bookmarked_tab', 'hoe_gate6')
      }
    }
    this.setState({
      bookmark_data: localStorage.getItem('bookmarked_tab')
    })
  }

  getBadgeCount(data) {
    this.setState({ badgeCount2: data.pending_tender_request, badgeCount: data.pending_timesheet_request + data.pending_hours_request })
  }

  render() {

    const { classes } = this.props;

    const handleChange = (event, newValue) => {
      this.props.requestCountRefresh(null)
      this.setState({ value: newValue })
      this.props.handleView(newValue)
    };

    // const ratingClick = () => {
    //   this.props.ratingClick()
    // };

    const handleBroadCastClose = () => {
      this.setState({ openBroadCast: false })
    };

    return (
      <Container disableGutters maxWidth={false} >
        <TabsUnstyled value={this.state.value} onChange={handleChange} >
          <Box sx={{ display: 'flex', flexDirection: 'row', }}>
            <TabsList >
              <Tab value={'my_tasks'}>
                MyTasks
              </Tab>
              {(this.props.myTaskData.lead_in_teams !== 0) ?
                <Tab value={'team_tasks'}>
                  <StyledBadge anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }} badgeContent={this.state.badgeCount} color="secondary">
                    TeamTasks
                  </StyledBadge>
                </Tab> : null}
              {(access.access && access.access.l4a && access.access.l4a.l4a_a) ?
                <Tab value={'project_tasks'}>
                  <StyledBadge anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }} badgeContent={this.state.badgeCount2} color="secondary">
                    ProjectTasks
                  </StyledBadge>
                </Tab> : null}
              {((access.access && access.access.l4a && access.access.l4a.l4a_a) && ((access.access && access.access.l4a && access.access.l4a.l4a_g) || (access.access && access.access.l4a && access.access.l4a.l4a_h) || (access.access && access.access.l4a && access.access.l4a.l4a_i) || (access.access && access.access.l4a && access.access.l4a.l4a_m) || (access.access && access.access.l4a && access.access.l4a.l4a_d) || (this.props.myTaskData.pdm_for > 0))) ?
                <Tab value={'kpi'}>
                  KPI
                </Tab> : null}
              {(this.props.myTaskData.is_hoe) ?
                <Tab value={'hoe_gate6'}>
                  Gate6
                </Tab> : null}
              <Tab value={'tutorials'}>
                Tutorials
              </Tab>
            </TabsList>
            {!(sideOptionNoAccessList.includes(this.state.value)) ?
              <Box m={1} mt={1}>
                <Tooltip title='Refresh Data'>
                  <IconButton onClick={() => this.reload()}>
                    <RefreshIcon color={this.state.icon_color} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={(this.state.bookmark_data === this.state.value) ? 'Remove Bookmark' : 'Bookmark current tab'}>
                  <IconButton onClick={() => this.bookMarkTab(this.state.value)}>
                    {(this.state.bookmark_data === this.state.value) ? <BookmarkIcon color={this.state.icon_color} /> : <BookmarkBorderIcon color={this.state.icon_color} />}
                  </IconButton>
                </Tooltip>
              </Box> : null}
            {/* {this.state.averageRating ?
              <div style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={ratingClick}>
                <Typography variant="caption" component="legend">Rating!</Typography>
                <Rating precision={0.25} name="read-only" value={this.state.averageRating} readOnly size="small" />
              </div>
              : null} */}
            {/* {(user.office_loc === 1) ?
              <Box marginLeft={screenSizeCheck() ? '5px' : '10px'}>
                <Card className='glow-on-hover' style={{ background: getSecondaryColor(), borderRadius: '5px', color: getPrimaryColor() }}>
                  <CardActionArea onClick={() => this.setState({ openBroadCast: true })}>
                    <CardContent>
                      <Box display={'flex'} justifyContent="center" flexDirection="row" alignItems="center"  >
                        <Box flexDirection="row" display="flex" justifyContent="center" marginRight={'5px'}>
                          <EmojiEventsIcon fontSize={'small'} />
                        </Box>
                        <Box fontSize='14px' fontWeight={'bold'}>
                          SBL 2024
                        </Box>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
              : null} */}

          </Box>
          <TabPanel value={'my_tasks'} ><MyTasks ref={this.progress} data={this.props.myTaskData} show_profile={true} handleClick={this.handleClick} refreshData={this.refreshData} refreshSubTender={this.refreshSubTender} taskdata={this.props.taskdata} /></TabPanel>
          <TabPanel value={'team_tasks'} ><TeamTasks ref={this.progress} handleClick={this.handleClick} is_teamtasks={true} launchAuto={this.launchAuto} openLoader={this.openLoader} /></TabPanel>
          <TabPanel value={'project_tasks'}><ProjectTasks ref={this.progress} /></TabPanel>
          <TabPanel value={'kpi'}><KpiDashboard ref={this.progress} /></TabPanel>
          <TabPanel value={'hoe_gate6'}><HoeGate6 ref={this.progress} isHoe={false} /></TabPanel>
          <TabPanel value={'tutorials'}><Tutorials /></TabPanel>
        </TabsUnstyled>
        <div>
          <Dialog fullScreen open={this.state.openBroadCast} onClose={handleBroadCastClose} TransitionComponent={Transition}>
            <AppBar position='relative' className='grad'>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleBroadCastClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6">
                  Senior's Badminton League 2024
                </Typography>
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                  {/* <Button color='secondary' style={{ marginRight: '5px' }} variant='contained' onClick={() => window.open('https://balfourbeatty-my.sharepoint.com/:x:/p/venkata_rathinam/EbEOV4S7bu5AhHoc75bOWLsBvocncxk3_VpFIQYp9IiD6g?e=bbMN7b')}>fixtures</Button> */}
                  {/* <Button color='secondary' style={{ marginRight: '10px' }} variant='contained' onClick={() => window.open('https://bbparam.com/edcsport2022')}>Playback - EDC EKTA 2022</Button> */}
                  {/* <Button color='secondary' variant='contained' onClick={() => window.open('https://eur02.safelinks.protection.outlook.com/ap/b-59584e83/?url=https%3A%2F%2Fbalfourbeatty-my.sharepoint.com%2F%3Ab%3A%2Fp%2Fvenkata_rathinam%2FEYByblU9mbNEka-W_miVp_4BL9wqIJuI4kslGG692xGiqA%3Fe%3DckvlvB&data=05%7C01%7CRaja.Ramar%40balfourbeatty.com%7Ce72f804ae9a2401b1df208dbb8fc8a72%7Ca04222fe0c5c40bb842097a219ba514e%7C0%7C0%7C638307167327786935%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=gA%2F12LNp4zvS53dSP6CTh%2FIxeml6eKZtQLwzLDvhSbo%3D&reserved=0')}>Ground & scoring rules</Button> */}
                </div>
              </Toolbar>
            </AppBar>
            <BroadCast />
          </Dialog>

        </div>

      </Container >
    )
  }
}

export default withStyles(useStyles)(withSnackbar(Tablayout))