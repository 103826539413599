import React, { Component } from 'react'
import BroadCast from './BroadCast'
import { Toolbar, } from '@mui/material'
import { AppBar, Box } from '@material-ui/core';
import { isMobile } from '../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import moment from 'moment'
import { getPrimaryColor } from '../Helper/CommonThings';
import { LoopCircleLoading } from 'react-loadingg';

class BroadCastOpen extends Component {
    constructor(props) {
        super(props)

        this.state = {
            year: moment().year(),
            isLoading: true
        }
    }

    componentDidMount() {
        if (window.location.href.includes('2022')) {
            this.setState({ year: 2022, isLoading: false })
        } else {
            this.setState({ year: 2024, isLoading: false })
        }
    }

    render() {
        return (
            <div>
                {this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                    <div>
                        <AppBar position='relative'  className='grad'>
                            <Toolbar>
                                <Box fontWeight="fontWeightBold" fontSize={isMobile() ? '12px' : "h6.fontSize"}>
                                    {this.state.year === 2024 ? "SBL 2024" : "EDC Annual Sports 2022"} - Live Broadcast Powered by PaRaM
                                </Box>
                            </Toolbar>
                        </AppBar>
                        <BroadCast />
                    </div>}
            </div>
        )
    }
}
export default BroadCastOpen