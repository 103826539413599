import React, { Component } from 'react'
import { Box, Typography, IconButton, Dialog, DialogContent } from '@material-ui/core'
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading'
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import ManageRole from './ManageRole';
import { getPrimaryColor } from '../../Helper/CommonThings';
// const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));
var roleData = {};
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class ManageRoles extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            openAdd: false,
            isLoading: true,
            addHours: 0,
            addEmp: 0,
            area: 'in',
            roles: {},
            open: false,
            columns: [
                { title: 'Roles', field: 'name', editable: 'never', },
                {
                    title: 'Grade', field: 'grade', editable: 'never',
                    lookup: { 'a': 'A', 'b': 'B', 'c': 'C', 'd': 'D', 'e': 'E', 'f': 'F', 'g': 'G' },
                },
                { title: 'Hours', field: 'hours', type: 'numeric' },
                {
                    title: 'Country', field: 'country',
                    lookup: { 'in': 'India', 'uk': 'UK', 'sl': 'Srilanka' },
                },
                {
                    title: 'Amount', field: 'cost', editable: 'never',
                    render: rowData => <Typography>{this.costCalc(rowData)}</Typography>
                },
                { title: 'Remarks', field: 'remark', },
            ],
        }
    }

    componentDidMount() {
        // console.log(this.props.rates)
        var roles1 = [];
        for (let i = 0; i < this.props.roles.length; i++) {
            const element = this.props.roles[i];
            if (element.t_tasks === this.props.value.id) {
                roles1.push(element)
            }
        }
        this.setState({
            roles: this.props.value,
            data: JSON.parse(JSON.stringify(roles1)),
            isLoading: false,
        });
    }

    costCalc(data1) {
        if (data1.country === 'in') {
            for (let i = 0; i < this.props.rates[0].t_rates.length; i++) {
                const element = this.props.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.hours * element.costin);
                }
            }
        } else {
            for (let i = 0; i < this.props.rates[0].t_rates.length; i++) {
                const element = this.props.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(data1.hours * element.costuk);
                }
            }
        }
        return 0;
    }

    render() {

        const handleClose = () => {
            this.setState({ open: false })
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                        <div>
                            <Box p={3}>
                                <MaterialTable
                                    key={3}
                                    title="Roles"
                                    columns={[
                                        { title: 'Roles', field: 'name', editable: 'never', },
                                        {
                                            title: 'Grade', field: 'grade', editable: 'never',
                                            lookup: { 'a': 'A', 'b': 'B', 'c': 'C', 'd': 'D', 'e': 'E', 'f': 'F', 'g': 'G' },
                                        },
                                        { title: 'Hours', field: 'hours', type: 'numeric' },
                                        {
                                            title: 'Country', field: 'country',
                                            lookup: { 'in': 'India', 'uk': 'UK', },
                                        },
                                        {
                                            title: 'Amount', field: 'cost', editable: 'never',
                                            render: rowData => <Typography>{this.costCalc(rowData)}</Typography>
                                        },
                                        { title: 'Remarks', field: 'remark', },
                                    ]}
                                    data={this.state.data}
                                    options={{
                                        padding: "dense",
                                        paging: false,
                                        actionsColumnIndex: 0
                                    }}
                                    actions={
                                        [
                                            rowData => ({
                                                icon: 'call_split',
                                                tooltip: 'Task Mapping',
                                                onClick: (event, rowData) => { roleData = rowData; this.setState({ open: true, }) },
                                            }),

                                        ]
                                    }
                                />
                            </Box>
                            <Dialog onClose={handleClose} fullwidth="true" maxWidth="xl" aria-labelledby="customized-dialog-title" open={this.state.open}>
                                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                                    Employee Task Mapping
                                </DialogTitle>
                                <DialogContent dividers>
                                    <ManageRole value={roleData} task={this.props.value} />
                                </DialogContent>
                            </Dialog>
                        </div>
                    )}
            </div>

        )
    }
}

export default withSnackbar(ManageRoles)