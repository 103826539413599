import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { LoopCircleLoading } from 'react-loadingg';
import { TextField, Box } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getCookie } from '../Helper/CookieHelper';
import UserPermission from './UserPermission/UserPermission';
import SecondaryRoles from './UserPermission/SecondaryRoles';
import UserWorkHours from './UserPermission/UserWorkHours';
import moment from "moment";
import copy from 'copy-to-clipboard';
import { errorMessage } from '../Helper/ErrorMessage';
import UserRoleChange from './UserDetail/UserRoleChange';
import { getPrimaryColor } from '../Helper/CommonThings';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));
var autocompdata = "";
const access = JSON.parse(getCookie("bb_ts_access"));
// var submitdata = null;

class StaffManage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            open: false,
            isLoading: true,
            isLoading2: true,
            rolesCust: {},
            roleAll: [],
            first_name: '',
            last_name: '',
            email: '',
            role_id: 0,
            access: 0,
            userData: {},
            autoinput: null,
            openSecRole: false,
            openWorkHours: false,
            allActiveEmail: "",
            showAllActiveEmail: false,
            openRoleHistory: false,
            companies: [],
            selectedCompany: null,
            companyLookup: {}
        }
    }


    componentDidMount() {
        this.fetchOps();
        this.fetchCompany();


        Service.get('/bb/api/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                var response = res.data;
                var data12 = { null: 'Not Available' };
                for (let i = 0; i < response.length; i++) {
                    const element = response[i];
                    data12[element.id] = element.name;
                }
                this.setState({ isLoading2: false, rolesCust: data12, roleAll: res.data, })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchOps() {
        var url = null
        if ((access && access.access && access.access.l16a && access.access.l16a.l16a_c) || user.is_hr) {
            url = "/bb/api/users/"
        } else if (access && access.access && access.access.l16a && access.access.l16a.l16a_d) {
            url = "/bb/api/users/?office_loc=1"
        } else if (access && access.access && access.access.l16a && access.access.l16a.l16a_e) {
            url = "/bb/api/users/?office_loc=0"
        }else if (access && access.access && access.access.l16a && access.access.l16a.l16a_j) {
            url = "/bb/api/users/?office_loc=2"
        }
        //?office_loc=1

        if (url) {
            Service.get(url, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    var jsondata = [];
                    var allActiveEmail = ""
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        element.name = element.first_name + " " + element.last_name;
                        if (element.is_admin) {
                            element.acc_type = 1;
                        } else if (element.is_lead) {
                            element.acc_type = 2;
                        } else if (element.is_staff) {
                            element.acc_type = 3;
                        } else {
                            element.acc_type = 0;
                        }
                        jsondata.push(element);
                        if (allActiveEmail === "" && element.is_active) {
                            allActiveEmail = element.email
                        } else if (element.is_active) {
                            allActiveEmail += ";" + element.email
                        }
                    }
                    this.setState({
                        data: jsondata,
                        isLoading: false,
                        allActiveEmail: allActiveEmail,
                    });
                    // console.log(jsondata)
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }

    }

    postData(dataPost) {
        // console.log(dataPost)
        if (parseInt(dataPost.acc_type) === 1 && !dataPost.is_superuser) {
            dataPost.is_admin = true;
            dataPost.is_lead = true;
            dataPost.is_staff = true;
        } else if (parseInt(dataPost.acc_type) === 2 && !dataPost.is_superuser) {
            dataPost.is_admin = false;
            dataPost.is_lead = true;
            dataPost.is_staff = true;
        } else if (parseInt(dataPost.acc_type) === 3 && !dataPost.is_superuser) {
            dataPost.is_admin = false;
            dataPost.is_lead = false;
            dataPost.is_staff = true;
        } else if (parseInt(dataPost.acc_type) === 0 && !dataPost.is_superuser) {
            dataPost.is_admin = false;
            dataPost.is_lead = false;
            dataPost.is_staff = false;
        }

        // delete dataPost['id'];
        Service.put("/bb/api/users/" + dataPost.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: dataPost,
        })
            .then(res => {
                // console.log(res.data)
                this.setState({ open: false, });
                this.props.enqueueSnackbar('Role changed for ' + dataPost.first_name + ' ' + dataPost.last_name, {
                    variant: 'success'
                });
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    createUser() {
        if (!this.state.first_name || !this.state.last_name || !autocompdata || !this.state.email || !this.state.selectedCompany) {
            this.props.enqueueSnackbar('All the fields are required.', {
                variant: 'warning',
            });
        } else if (!this.state.email.includes("@balfourbeatty.com")) {
            this.props.enqueueSnackbar('Only Balfour Beatty email address is allowed.', {
                variant: 'warning',
            });
        } else {

            var json = {
                email: this.state.email.toLowerCase(),
                password1: 'Password.1',
                password2: 'Password.1',
            }
            var json2 = {
                email: json.email,
                email_username: json.email,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                is_staff: this.state.access === 10 || this.state.access === 20 || this.state.access === 30,
                is_lead: this.state.access === 20 || this.state.access === 30,
                is_superuser: false,
                is_admin: this.state.access === 30,
                twof_auth: false,
                is_estimator: false,
                is_hr: false,
                is_active: true,
                role_id: autocompdata !== "" ? autocompdata.id : null,
                company: this.state.selectedCompany.id,
            }
            this.setState({ isLoading: true })
            Service.post('/rest-auth/registration/', {
                data: json,
            })
                .then(res => {
                    this.props.enqueueSnackbar(json.email + ' is created', {
                        variant: 'success',
                    });

                    Service.put("/bb/api/request/password/" + json.email + "/?email=" + json.email, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: json2,
                    })
                        .then(res1 => {
                            // console.log(res.data)
                            this.setState({ open: false, isLoading: false });
                            this.props.enqueueSnackbar('Roles and other attributes are filled', {
                                variant: 'success'
                            });
                            this.autoInputHoursValue(res1.data)
                            Service.post(`/bb/api/user/detail/`, {
                                headers: {
                                    Authorization: "Token " + token,
                                },
                                data: {
                                    join_date: moment().format('YYYY-MM-DD'),
                                    uid: res1.data.id
                                },
                            })
                                .then(res => {
                                    this.setState({
                                        first_name: '',
                                        last_name: '',
                                        email: '',
                                        selectedCompany: null,
                                        autoinput: null,
                                    })
                                })
                                .catch(error => {
                                    this.props.enqueueSnackbar('Please go to user detail page and fill details.', {
                                        variant: 'warning'
                                    });
                                    console.log(error)
                                    errorMessage(this.props, error)
                                });
                            this.fetchOps();
                        })
                        .catch(error => {
                            this.setState({ open: false, isLoading: false });
                            // console.log(error)
                            errorMessage(this.props, error)
                            this.fetchOps();
                        });

                })
                .catch(error => {
                    console.log(error)
                    if (error.data.email) {
                        this.props.enqueueSnackbar(error.data.email[0], {
                            variant: 'warning',
                        });
                    } else if (error.data.password1) {
                        this.props.enqueueSnackbar(error.data.password1[0], {
                            variant: 'warning',
                        });
                    } else {
                        this.setState({ isLoading: false });
                        errorMessage(this.props, error)
                    }
                });

        }
    }

    autoInputHoursValue(user_data) {
        var input = []
        var eachHour = 0
        if (user_data.office_loc === 0) {
            eachHour = 7.5
        } else if (user_data.office_loc === 1) {
            eachHour = 8
        }
        for (let i = 0; i < 5; i++) {
            input.push({
                created_by: user.id,
                uid: user_data.id,
                day: i + 1,
                hours: eachHour
            })

        }

        Service.post('/bb/api/user/workhours/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: input
        })
            .then(res => {
                this.fetchOps();
                this.props.enqueueSnackbar('Default hours added!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                this.fetchOps();
                errorMessage(this.props, error)
            });
    }

    onTagsChange = (event, values) => {
        // console.log(values)
        autocompdata = values;
    }

    inputChange = ev => {
        let name = ev.target.id;
        let value = ev.target.value;
        this.setState({ [name]: value });
    };

    onCompanyChange = (event, values) => {
        this.setState({ selectedCompany: values });
    }

    fetchCompany() {
        Service.get('/bb/api/company/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var companyLookup = {}
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    companyLookup[element.id] = element.name
                }
                this.setState({ companies: res.data, companyLookup: companyLookup })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }


    render() {

        const handleClose = () => {
            this.setState({ open: false, });
        };

        const handleOpenSecRole = () => {
            this.setState({ openSecRole: false, openWorkHours: false, openRoleHistory: false });
        };

        // const handleChange = (event) => {
        //     this.setState({ access: event.target.value })
        // };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <MaterialTable
                            title="Manage Users"
                            columns={[
                                { title: 'Id', field: 'id' },
                                { title: 'First Name', field: 'first_name' },
                                { title: 'Last Name', field: 'last_name' },
                                { title: 'Email', field: 'email', editable: 'never' },
                                { title: 'SBU', field: 'company', lookup: this.state.companyLookup },
                                {
                                    title: 'Role', field: 'role_id',
                                    lookup: this.state.rolesCust,
                                },
                                {
                                    title: 'Active', field: 'is_active',
                                    lookup: { 'true': 'Active', 'false': 'Inactive' },
                                },
                                {
                                    title: 'Office Location', field: 'office_loc',
                                    lookup: { 0: 'UK', 1: 'EDC', 2: 'SL' },
                                },
                                // {
                                //     title: 'Access', field: 'acc_type',
                                //     lookup: { 3: 'Team Lead (Can create their own teams)', 2: 'Lead (Can Approve TimeSheet)', 1: 'Admin (Can Manage Projects)', 0: 'No Special Access' },
                                // },
                            ]}
                            data={this.state.data}
                            options={{
                                padding: "dense",
                                pageSize: 50,
                                pageSizeOptions: [10, 50, 100],
                                paging: false,
                                actionsColumnIndex: -1,
                                filtering: true
                            }}
                            actions={
                                [
                                    {
                                        icon: 'add_box',
                                        tooltip: 'Invite User',
                                        isFreeAction: true,
                                        onClick: () => {
                                            this.setState({ open: true })
                                        },
                                    },

                                    rowData => ({
                                        icon: 'vertical_split',
                                        tooltip: 'Secondary Roles',
                                        onClick: () => {
                                            this.setState({
                                                userData: rowData,
                                                openSecRole: true
                                            })
                                        },
                                    }),

                                    rowData => ({
                                        icon: 'av_timer',
                                        tooltip: 'Work Hours',
                                        onClick: () => {
                                            this.setState({
                                                userData: rowData,
                                                openWorkHours: true
                                            })
                                        },
                                    }),

                                    rowData => ({
                                        icon: 'history',
                                        tooltip: 'Role Change History',
                                        onClick: () => {
                                            this.setState({
                                                userData: rowData,
                                                openRoleHistory: true
                                            })
                                        },
                                    }),

                                ]
                            }

                            detailPanel={rowData => {
                                return (
                                    <UserPermission value={rowData} />
                                )
                            }}

                            editable={{
                                // onRowAdd: newData =>
                                //     new Promise(resolve => {
                                //         setTimeout(() => {
                                //             resolve();
                                //             this.setState({ save: false });
                                //             this.setState(prevState => {
                                //                 const data = [...prevState.data];
                                //                 newData.roles = [];
                                //                 data.push(newData);
                                //                 return { ...prevState, data };
                                //             });
                                //         }, 60);
                                //     }),
                                onRowUpdate: (newData, oldData) =>
                                    new Promise(resolve => {
                                        setTimeout(() => {
                                            resolve();
                                            if (oldData) {
                                                this.setState({ save: false });
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data[data.indexOf(oldData)] = newData;
                                                    return { ...prevState, data };
                                                });
                                            }
                                        }, 60);
                                        this.postData(newData);
                                    }),
                                // onRowDelete: oldData =>
                                //     new Promise(resolve => {
                                //         setTimeout(() => {
                                //             resolve();
                                //             this.setState({ save: false });
                                //             this.setState(prevState => {
                                //                 const data = [...prevState.data];
                                //                 data.splice(data.indexOf(oldData), 1);

                                //                 return { ...prevState, data };
                                //             });
                                //         }, 60);
                                //     }),
                            }}
                        />
                        <Button onClick={() => {
                            copy(this.state.allActiveEmail)
                            this.props.enqueueSnackbar('Emails copied to clipboard!', {
                                variant: 'success'
                            });
                        }}>Copy all active Email ID's</Button>
                        {/* {(this.state.showAllActiveEmail) ? (this.state.allActiveEmail) : (null)} */}
                        <Dialog
                            open={this.state.open}
                            TransitionComponent={Transition}
                            keepMounted
                            fullWidth
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-slide-title">{"Create New User"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    Careful while entering the data because email field can't be changed later invitation.
                                </DialogContentText>
                                <Box display="flex" flexDirection="row" p={1} m={1} >
                                    <TextField id="first_name" label="First Name" variant="outlined"
                                        value={this.state.first_name}
                                        fullWidth
                                        required
                                        onChange={(e) => this.inputChange(e)} />
                                </Box>
                                <Box display="flex" flexDirection="row" p={1} m={1} >
                                    <TextField id="last_name" label="Last Name" variant="outlined"
                                        value={this.state.last_name}
                                        fullWidth
                                        required
                                        onChange={(e) => this.inputChange(e)} />
                                </Box>
                                <Box display="flex" flexDirection="row" p={1} m={1} >
                                    <TextField id="email" label="Email" variant="outlined"
                                        value={this.state.email}
                                        fullWidth
                                        required
                                        onChange={(e) => this.inputChange(e)} />
                                </Box>

                                <Box display="flex" flexDirection="row" p={1} m={1} >
                                    <Autocomplete
                                        required
                                        id="combo-box-demo"
                                        value={this.state.selectedCompany}
                                        style={{ width: '100%' }}
                                        options={this.state.companies}
                                        onChange={this.onCompanyChange}
                                        // onInputChange={(event, newInputValue) => { this.setState({ selectedCompany: newInputValue }) }}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} label="Select SBU" variant="outlined" />}
                                    />
                                </Box>
                                {/* <Box display="flex" flexDirection="row" p={1} m={1} >
                                        <FormControl variant="outlined" fullWidth >
                                            <InputLabel id="demo-simple-select-outlined-label">Access</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={this.state.access}
                                                onChange={handleChange}
                                                label="Access"
                                            >
                                                <MenuItem value={0}>No Special Access</MenuItem>
                                                <MenuItem value={10}>Team Lead (Can create their own teams)</MenuItem>
                                                <MenuItem value={20}>Lead (Can Approve TimeSheet)</MenuItem>
                                                <MenuItem value={30}>Admin (Can Manage Projects)</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box> */}

                                <Box display="flex" flexDirection="row" p={1} m={1} >
                                    <Autocomplete
                                        id="combo-box-demo"
                                        inputValue={this.state.autoinput}
                                        style={{ width: '100%' }}
                                        options={this.state.roleAll}
                                        onChange={this.onTagsChange}
                                        onInputChange={(event, newInputValue) => { this.setState({ autoinput: newInputValue }) }}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} label="Choose User Role" variant="outlined" />}
                                    />
                                </Box>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={e => this.createUser()} color="primary">
                                    Invite User
                                </Button>
                            </DialogActions>
                        </Dialog>



                        <Dialog open={this.state.openSecRole} maxWidth="xl" onClose={handleOpenSecRole} aria-labelledby="form-dialog-title">

                            <DialogContent>

                                <SecondaryRoles selectedUser={this.state.userData} />

                            </DialogContent>

                            <DialogActions>
                                <Button onClick={handleOpenSecRole} color="primary">
                                    Close
                                </Button>

                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.openWorkHours} maxWidth="xl" onClose={handleOpenSecRole} aria-labelledby="form-dialog-title">

                            <DialogContent>

                                <UserWorkHours selectedUser={this.state.userData} />

                            </DialogContent>

                            <DialogActions>
                                <Button onClick={handleOpenSecRole} color="primary">
                                    Close
                                </Button>

                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.openRoleHistory} maxWidth="md" onClose={handleOpenSecRole} aria-labelledby="form-dialog-title">

                            <DialogContent>

                                <UserRoleChange value={this.state.userData.id} />

                            </DialogContent>

                            <DialogActions>
                                <Button onClick={handleOpenSecRole} color="primary">
                                    Close
                                </Button>

                            </DialogActions>
                        </Dialog>

                    </div>
                )}
            </div>

        )
    }
}

export default withSnackbar(StaffManage);