import React, { Component } from 'react'
import { Button, Box, TextField } from '@material-ui/core'
import { withSnackbar } from 'notistack';
import Service from "../Networking/networkutils";
import { getCookie, deleteCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';

var passwordValidator = require('password-validator');
const token = getCookie("bb_ts_token");
// Create a schema
var schema = new passwordValidator();

class PasswordChange extends Component {

    constructor(props) {
        super(props)

        this.state = {
            old: '',
            new1: '',
            new2: '',
            match: false,
        }
    }

    postChange() {

        if (this.state.new1 === this.state.new2) {
            schema
                .is().min(8)                                    // Minimum length 8
                .is().max(100)                                  // Maximum length 100
                .has().uppercase()                              // Must have uppercase letters
                .has().lowercase()                              // Must have lowercase letters
                .has().digits()                                 // Must have digits
                .has().not().spaces();                           // Should not have spaces

            if (!schema.validate(this.state.new1)) {
                this.props.enqueueSnackbar('Please follow Password Rules', {
                    variant: 'warning',
                });
            } else {
                var json = {
                    old_password: this.state.old,
                    new_password1: this.state.new1,
                    new_password2: this.state.new2,
                }
                Service.post('/rest-auth/password/change/', {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: json,
                })
                    .then(res => {
                        this.props.enqueueSnackbar('Password Changed', {
                            variant: 'success',
                        });
                        Service.post('/rest-auth/logout/', {
                            headers: {
                                Authorization: "Token " + token,
                            },
                            data: json,
                        })
                            .then(res => {
                                deleteCookie("bb_ts_token");
                                deleteCookie("bb_ts_user");
                                alert("All your devices are logged out. Please log in again!.");
                                window.location.href = "/login";
                            })
                            .catch(error => {
                                errorMessage(this.props, error)
                            });
                    })
                    .catch(error => {
                        console.log(error.data)
                        if (error.data.old_password) {
                            this.props.enqueueSnackbar(error.data.old_password[0], {
                                variant: 'error'
                            });
                        } else {
                            errorMessage(this.props, error)
                        }
                        
                    });
            }
        } else {
            this.props.enqueueSnackbar('Confirm password does not match', {
                variant: 'warning',
            });
        }

    }

    render() {

        const handleOldChange = (event) => {
            this.setState({ old: event.target.value })
        };
        const handleNew1Change = (event) => {
            this.setState({ new1: event.target.value })
        };
        const handleNew2Change = (event) => {
            this.setState({ new2: event.target.value, match: (this.state.new1 !== event.target.value) })
        };

        return (
            <div>
                <Box display="flex" p={1} bgcolor="background.paper" justifyContent="center">
                    <Box fontSize={22} fontWeight="fontWeightBold">Change Password</Box>
                </Box>
                <Box display="flex" p={1} bgcolor="background.paper" justifyContent="center">
                    <TextField id="old_password" label="Old Password" variant="outlined"
                        type='password'
                        onChange={handleOldChange}
                        value={this.state.old} />
                </Box>

                <Box display="flex" bgcolor="background.paper" justifyContent="center"><h4>Password Rules:</h4></Box>
                <Box display="flex" bgcolor="background.paper" justifyContent="center">
                    <p align='justify'>
                        1) Your password must be between 8 and 30 characters.<br />
                                2) Your password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)<br />
                                3) Your password must contain at least one lowercase letter.<br />
                                4) Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)<br />
                    </p>
                </Box>


                <Box display="flex" p={1} bgcolor="background.paper" justifyContent="center">
                    <TextField id="new_password1" label="New Password" variant="outlined"
                        type='password'
                        onChange={handleNew1Change}
                        value={this.state.new1} />
                </Box>
                <Box display="flex" p={1} bgcolor="background.paper" justifyContent="center">
                    <TextField id="new_password2" label="Confirm Password" variant="outlined"
                        type='password'
                        onChange={handleNew2Change}
                        value={this.state.new2}
                        helperText={(this.state.match) ? ("Password does not match!") : (null)}
                        error={this.state.match} />
                </Box>
                <Box display="flex" p={1} bgcolor="background.paper" justifyContent="center">
                    <Button color='primary'
                        variant="contained"
                        onClick={e => this.postChange()}>Submit</Button>
                </Box>
            </div>
        )
    }
}

export default withSnackbar(PasswordChange);