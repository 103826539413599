import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { CsvBuilder } from 'filefy';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles, Box, CircularProgress, Toolbar, Tooltip, } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { getDarkMode } from '../../../Helper/CommonThings';
import { withSnackbar } from 'notistack';
import TimesheetTemplateRow1 from './TimesheetTemplateRow1';

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#00b300',
            contrastText: '#fff',
        },
        secondary: {
            main: '#cca300',
            contrastText: '#fff',
        },
        error: {
            main: "#ff0000",
            contrastText: '#fff',
        },
        type: getDarkMode(),
    },
})

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

class TimesheetTemplate1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            toDia: props.toDia,
            toDia2: props.toDia2,
            toDia3: props.toDia3,
            rowLoading: false,
        }
        this.handleOpen = this.handleOpen.bind(this)
    }

    getTotal(data) {
        var total = 0
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.total) {
                total += element.total
            }
        }
        return total
    }

    handleOpen(i) {
        if (this.props.handleOpen) {
            this.props.handleOpen(i)
        }
    }

    render() {

        const exportCsv = (columnList, initialData) => {
            const columns = columnList.filter(columnDef => {
                return !columnDef.hidden && columnDef.field && columnDef.export !== false;
            });

            const data = initialData.map(rowData =>
                columns.map(columnDef => {
                    return columnDef.lookup ? columnDef.lookup[rowData[columnDef.field]] : rowData[columnDef.field];
                })
            );

            const builder = new CsvBuilder("Timesheet booked by " + (this.state.toDia[1][0] ? this.state.toDia[1][0].uname : "") + " for week [" + (this.state.toDia[0][1] ? this.state.toDia[0][1].title : "") + " - " + (this.state.toDia[0][7] ? this.state.toDia[0][7].title : "") + '].csv');
            builder
                .setDelimeter(',')
                .setColumns(columns.map(columnDef => columnDef.title))
                .addRows(data)
                .exportFile();
        };

        return (
            <div>
                <ThemeProvider theme={bbColorTheme} >
                    <Toolbar>
                        <Box display="flex" flexDirection="row" width={"100%"}>
                            <Box flexGrow={1}>
                                <Typography variant="h6" id="tableTitle" component="div">
                                    {"Timesheet booked by " + (this.state.toDia[1][0] ? this.state.toDia[1][0].uname : "") + " for week [" + (this.state.toDia[0][1] ? this.state.toDia[0][1].title : "") + " - " + (this.state.toDia[0][7] ? this.state.toDia[0][7].title : "") + "]"}
                                </Typography>
                            </Box>
                            <Tooltip title={"Export as CSV"}>
                                <IconButton size='small' onClick={() => exportCsv(this.state.toDia2[0], this.state.toDia2[1])}>
                                    <SaveAltIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Toolbar>

                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    {this.state.toDia3[0].map((row) => (
                                        <StyledTableCell key={row.title} align={row.title === 'Projects' ? "left" : "center"}>{row.title}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.toDia3[1].map((row, i) =>
                                    this.state.rowLoading ? <CircularProgress key={i} /> :
                                        <TimesheetTemplateRow1
                                            mini_table_data_start={this.props.mini_table_data_start}
                                            table_data_start={this.props.table_data_start}
                                            index={i}
                                            approve_status={this.state.data.approve_status}
                                            key={row.projects}
                                            row={row}
                                            toDia3={this.state.toDia3}
                                            toDia2={this.state.toDia2}
                                            handleSelect={this.handleSelect}
                                            handleIndivSelect={this.handleIndivSelect}
                                            open={row.is_open}
                                            handleOpen={this.handleOpen}
                                        />)}
                                <StyledTableRow >
                                    <StyledTableCell align="left">
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Typography >{this.getTotal(this.state.toDia3[1])}</Typography>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ThemeProvider>
            </div>
        );
    }
}

export default withSnackbar(TimesheetTemplate1);